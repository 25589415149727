#block-helper-admin-helper-theme-switcher{
  position:fixed;
  right:0;
  bottom:100px;
  background:$primary-color;
  float: none !important;
  display: block;
  width: 172px !important;
  padding:10px !important;
  select,
  input{
    width:100%;
  }
}