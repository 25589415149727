@mixin holiday-home-teaser {
  a{
    @include clearfix;
    &:hover{
      text-decoration:none;
    }
    .discounts{
      top:60px;
      left: 0;
    }
  }
  .title-and-city {
    max-width: calc(100% - 50px);
    margin-bottom: 10px;
    h4.title {
      font-size: 16px;
      font-weight: $bold-weight;
      margin: 0 !important;
    }
    .city {
      color: $grey-color;
    }
  }

  position:relative;
  .discounts {
    @include teaser-discounts;
  }
  .rating {
    float: right;
    font-size: 22px;
    font-weight: $bold-weight;
    color: $secondary-color;
    text-align: right;
    line-height:1;
    .total-ratings {
      color: $grey-color;
      font-weight: normal;
      font-size: 12px;
      margin-top:2px;
      .mobile{
        display:none;
      }
      @include breakpoint(0 $tab){
        .desktop{
          display:none;
        }
        .mobile{
          display:block;
        }
      }
      #similar-home &,
      #recently-watched &{
        .desktop{
          display:none;
        }
        .mobile{
          display:block;
        }
      }

    }
  }
  .highest-average-field {
    @include breakpoint(0 $tab) {
      display: none;
    }
    .label {
      font-size: 14px;
      color: $base-font-color;
    }
    .value {
      color: $secondary-color;
      margin-left: 5px;
      font-size: 16px;
    }
  }
}