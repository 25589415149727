.review-slider-wrapper {
  background: $grey-background-color;
  position: relative;
  .inner {
    padding: $gutter-width/2 $gutter-width;
    .review-slider-title {
      font-size: 24px;
      @include breakpoint(0 $tab){
        font-size:20px;
      }
      font-family:$heading-font;
      line-height: 1;
      margin-bottom: 5px;
      color: $heading-font-color;
      &.very-good {
        color: $gold;
        font-weight: $bold-weight;
      }
      &.good {
        font-weight: $bold-weight;
      }
    }
    .highest-per-field {
      font-size: 16px;
      position:absolute;
      bottom:$gutter-width/2;
      width:calc(100% - #{$gutter-width}*2);
      left:$gutter-width;
    }
    .link-to-all {
      float: right;
      a{
        font-style:italic;
        color:darken($grey-color,10);
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }

    .review-slider {
      position: absolute;
      left: 0;
      width: 100%;
      top: 30px;
      height: calc(100% - 40px - #{$gutter-width});
      margin-bottom: 0;
      .slick-list {
        margin: 10px $gutter-width;
      }
      .slick-slide{
        display:flex;
        align-items:center;
        height:100%;
      }
      .slide{
        display:none;
      }
      .slick-list .slide{
        display:block;
      }
      button {
        z-index: 999;
        width: 10px;
        height: 25px;
        margin-top: -15px;
        &:before {
          color: black;
          font-size: 30px;
        }
      }

      button.slick-prev {
        @include icon(angle-left);
        left: $gutter-width;
      }
      button.slick-next {
        @include icon(angle-right);
        right: $gutter-width;
      }
      @include breakpoint(0 $mobile){
        button.slick-prev {
          left:$gutter-width/2;
        }
        button.slick-next {
          right: $gutter-width/2;
        }
      }
      .slick-list {
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        .slick-track {
          height: 100%;
          .slide {
            height: 100%;
            outline: none;
            .slider-item {
              padding-left: $gutter-width*2;
              padding-right: $gutter-width*2;
              @include breakpoint(0 $mobile){
                padding-left:$gutter-width;
                padding-right: $gutter-width;
              }
              height: 100%;
              width: calc(100%);
              display: table;
              .inner {
                display: table-cell;
                vertical-align: middle;
                padding: 0;
                .review {
                  font-style: italic;
                }
              }
            }
          }
        }
      }
    }
  }
}