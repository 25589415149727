$contact_photo_width: 100px;

a.complex.teaser,
a.holiday-home.teaser{
  @include holiday-home-teaser;

  .highest-average-field {
    display: none;
  }
}

.node-holiday-home,
.node-holiday-home-xl,
.node-complex {
  .sale-icons {
    display: flex;
    justify-content: space-between;

    .item {
      &:before {
        color: $secondary-color;
        font-size: 2rem;
      }

      &.building-year {
        @include icon(bricks);
      }

      &.rooms {
        @include icon(door);
      }

      &.plot-area {
        @include icon(plot-area);
      }

      &.living-space {
        @include icon(surface);
      }

      text-align: center;

      label {
        display: block;
        font-weight: bold;
      }
    }
  }

  &.full {
    .sale-icons {
      padding: 1em 0;
      border-bottom: 1px solid $border-color;
      border-top: 1px solid $border-color;
      margin-bottom: 2rem;

      .item {
        width: 200px;
      }
    }

    .sale-info {
      > *{
        padding: 1rem;
      }
      .for-sale-text{
        padding: 8px 1rem;
        border-bottom:1px solid white;
        color:grey;
      }
      font-size: 1rem;
      font-weight: bold;
      background: $form-background-color;
      text-align: center;
      .sale-price {
        font-size: 2rem;
      }
    }


    .sale-contact{
      padding:1.6rem 2rem;
      background:$form-background-color;
      margin-top:$gutter-width;
      *{
        &:not(:last-child){
          margin-bottom:.5rem;
        }
      }
      .sale-pdf{
        @include icon(download);
        font-size:.9em;
        &:before{
          margin-right:10px;
        }
      }
      .sale-phone{
        @include icon(phone);
        font-size:.9em;
        &:before{
          margin-right:10px;
        }
      }
      .sale-broker{
        font-weight:bold;
      }
      .helper-sale-contact{
        margin-top:.5rem;
        width:100%;
      }
    }

    .booking-link {
      @include breakpoint($tab) {
        display: none;
      }
      @include breakpoint (0 $tab) {
        a {
          width: 100%;
          background: $tertiary-color;
          text-align: center;
          display: block;
          z-index: 9999;
          color: white;
          text-size: 16px;
          padding: 10px;

          &.active {
            text-decoration: none;
          }

          &:hover {
            background: darken($tertiary-color, 10);
            text-decoration: none;
          }
        }

      }
    }

    .anchor {
      // put the anchor higher to compensate for fixed header.
      top: -70px;

      .adminimal-menu & {
        top: -70px - 29px;
      }

      &#photos-anchor {
        top: 0;

        .adminimal-menu & {
          top: -29px;
        }
      }

      visibility: hidden;
      position: relative;
      display: block;
    }

    .node-type-holiday-home-xl &,
    .node-type-complex &,
    .node-type-holiday-home & {
      @include breakpoint(0 $mobile) {
        margin: 0 $gutter-width/2;
      }

      .layout-row {
        @include clearfix;
        @include breakpoint($tab) {
          > .left {
            width: calc(100% - #{$sidebar-width} - #{$gutter-width});
            float: left;
          }
          > .right {
            float: right;
            width: $sidebar-width;
          }
        }

        &.row-1 {
          @include breakpoint(0 $tab) {
            margin-bottom: 10px;
          }

          h1.title {
            display: inline-block;
            @include breakpoint(0 $tab) {
              width: auto !important;
              display: block !important;
              padding-left: 0 !important;
              padding-right: 0;
            }
          }

          .stars {
            float: none;
            margin: 12px 0 0px 10px;
            display: inline-block;
            vertical-align: top;
          }

          .luxury-level {
            @include luxury-level;
            float: none;
            margin: 12px 0 0px 10px;
            display: inline-block;
            vertical-align: top;
            @include breakpoint(0 $tab) {
              margin: 10px 0;
            }
          }

          .home-code {
            float: right;
            color: $grey-color;
            margin: 12px 0;
            @include breakpoint(0 $tab) {
              float: right !important;
              margin: 10px 10px 10px 0;
            }
          }

          .side {
            @include breakpoint($tab) {
              width: $sidebar-width;
              float: right;
              margin-left: $gutter-width;
            }
            @include breakpoint(0 $tab) {
              float: right;
              .favorites-link {
                a {
                  margin-top: 2px;
                }
              }
            }

            .favorites-link {
              margin-left: 0;

              a {
                background: $tertiary-color;
                color: white;
                padding: 5px 10px;
                display: block;
                border-radius: 2px;
                margin: 5px 0;

                &.flag {
                  @include icon(heart-empty);
                }

                &.flagged {
                  @include icon(heart);
                }

                &:before {
                  margin-right: 5px;
                }

                &:hover {
                  text-decoration: none;
                  background-color: $grey-color;
                }
              }
            }

            .links {
              @include add-this;
              float: right;
              margin: 10px 0;
              @include breakpoint (0 $tab) {
                display: none;
              }
            }
          }
        }
      }
    }

    #description {
      ul.characteristics-of-trust-new {
        @include characteristics-of-trust;
      }
    }

    #reviews {
      @include clearfix;
      @include reviews;

      .group-inner > div {
        @include clearfix;
        padding-bottom: $gutter-width;
      }

      @include breakpoint($tab) {
        border-bottom: 1px solid $border-color;
        .node-type-complex & {
          border-bottom: none;
        }
      }
    }

    #complex {
      padding-top: $gutter-width;
      padding-bottom: $gutter-width;

      .content,
      .image {
        float: none;
        width: 100%;
        padding: 0;
        padding: 0;
        border: none;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      .main-characteristics {
        margin: $gutter-width 0;
        padding: $gutter-width 0;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        ul {
          margin: 0;
          float: none;
          @include clearfix;
        }
      }
    }

    #booking.group,
    #photos.group {
      padding: 0;
      border-top: none !important;
    }

    div.group {
      > h2 {
        padding: 30px 0 20px 0;
        margin-bottom: 0;
        font-size: 24px;
      }

      @include breakpoint(0 $mobile) {
        border-top: 1px solid $border-color;
        padding: 0;
        &.collapsible {
          padding: $gutter-width $gutter-width/2;
          @include breakpoint(0 $small_mobile) {
            padding: 0;
          }

          > h2 {
            margin-top: 0;
            margin-bottom: 0;
            @include icon('angle-down', 'after');

            &:after {
              float: right;
            }
          }

          &.collapsed {
            .group-inner {
              overflow: hidden;
              height: 0;
            }

            > h2 {
              @include icon('angle-right', 'after');
            }
          }

        }
        > h2 {
          margin: 0 10px;
          cursor: pointer;
          margin: 10px 0;
          padding: 10px 0;

        }

      }

    }

    .booking-widget, .supplements-complete .supplements-wrapper {
      margin-bottom: $gutter-width;
      width: 100%;
      display: block;
      background: $search-home-bg-color;
      padding: 0 $gutter-width $gutter-width $gutter-width;
      @include breakpoint(0 $mobile) {
        padding: 0 $gutter-width/2 $gutter-width/2 $gutter-width/2;
      }
      position: relative;
    }

    .particulars {
      @include clearfix;
      float: left;
      background: lighten($search-home-bg-color, 5%);
      width: 100%;

      padding: $gutter-width/2;
      margin-bottom: $gutter-width;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .row-3 {
      .subnavigation-bottom & {
        position: relative;

        > .right {
          position: static;
        }
      }
    }

    .row-3 > .right > .inner {
      @include breakpoint($tab) {
        .fixed-booking-widget & {
          position: fixed;
          max-height: 100%;
          top: 75px;
          width: $sidebar-width;
          z-index: 99;

          .booking-widget {
            margin-top: -75px;
          }
        }
        .adminimal-menu.fixed-booking-widget & {
          .booking-widget {
            margin-top: -71px;
          }
        }
        &.fixed-booking-widget.adminimal-menu & {
          .bookng-widget {
            margin-top: -71px;
          }
        }
        .subnavigation-bottom & {
          position: absolute;
          bottom: 0 !important;
          top: auto !important;
        }
        .subnavigation.adminimal-menu & {
          top: 100px;
        }

      }
    }

    .main-characteristics {
      margin-bottom: $gutter-width;
      @include main-characteristics;
    }

    .characteristics-contact {

      .contact-info {
        width: 100%;
        margin-bottom: 20px;

        &.include-photo {
          @include clearfix;

          .display-photo {
            width: $contact_photo_width;
            float: left;
          }

          .details {
            width: calc(100% - #{$contact_photo_width} - #{$gutter-width});
            float: right;
          }

          @include breakpoint(0 $small_mobile) {
            text-align: left;
            .details,
            .display-photo {
              width: 100%;
              float: none;

              img {
                width: 100px;
                margin-bottom: 20px;
              }
            }
          }
        }

        .display-photo {
          img {
            width: 100%;
            border-radius: 50%;
          }
        }

        .details {
          @include icon_list;

          .display-name {
            font-family: $heading-font;
            font-size: 20px;
            color: $secondary-color;
            margin-bottom: 10px;
            padding-left: 0;
            font-weight: $bold-weight;
          }

          > div,
          > a {
            width: 100%;
            margin: 5px 0 !important;

            &:before {
              font-size: 14px;
            }

            &:not(.display-name) {
              &.email-plain, &.mail {
                @include icon('mail');
              }

              &.phone-plain, &.phone {
                @include icon('phone');
              }

              &.website {
                @include icon('link-ext');
              }

              &.facebook-page {
                @include icon('facebook');
              }

              &.address {
                @include icon('address');
              }

              &.address {
                .display-name {
                  font-weight: bold;
                }

                .street,
                .postcode-city {
                  > div {
                    display: inline;
                  }
                }
              }
            }
          }

          > a {
            color: $secondary-color;

            &:hover {
              color: black;
            }
          }
        }
      }
    }

    #characteristics {
      .layout,
      .characteristics {
        > .row {
          margin: $gutter-width 0;
          clear: both;
          @include clearfix;

          h4 {
            width: 200px;
            float: left;
            color: $secondary-color;
            font-size: 1em;
            @include breakpoint($small_mobile $mobile) {
              width: 130px;
            }
            @include breakpoint(0 $small_mobile) {
              width: 100%;
              float: none;
            }
          }

          ul {

            width: calc(100% - 200px);
            @include breakpoint($small_mobile $mobile) {
              width: calc(100% - 130px);
            }
            @include breakpoint(0 $small_mobile) {
              width: 100%;
            }
            float: right;

            li {
              float: left;
              width: calc(50% - #{$gutter-width});
              @include breakpoint(0 $small_mobile) {
                width: 100%;
              }

              p {
                margin-bottom: 0;
              }

              margin-right: $gutter-width;
              font-size: 1em;
              font-weight: 400;
              margin-bottom: 8px;
            }

            .see-more {
              text-align: center;
              width: 100%;
              float: none;
              display: inline-block;
              padding: 10px;
              margin-top: 10px;
              margin-bottom: 10px;
              border-top: 1px solid $border-color;
              color: $secondary-color;
              cursor: pointer;
              @include icon('plus', 'before');

              &:before {
                margin-right: 5px;
              }

              &:hover {
                text-decoration: none;
                color: $base-font-color;
              }
            }

            .hide-by-default {
              display: none;
            }

            &.more-shown {
              .hide-by-default {
                display: block;
              }

              .see-more {
                display: none;
              }
            }
          }
        }

        .show-layout {
          width: 100%;
          padding: 10px;
          display: inline-block;
          text-align: center;
          @include icon('plus', 'before');
          border-top: 1px solid $border-color;
          cursor: pointer;
          color: $secondary-color;

          &:before {
            padding-right: 5px;
          }

          .show-layout & {
            display: none;
          }

          &:hover {
            color: $base-font-color;
            text-decoration: none;
          }
        }
      }

      .layout {
        display: none;

        .show-layout & {
          display: block;
        }
      }
    }

    #map-and-surroundings {
      .distances {
        @include clearfix;
        margin-bottom: $gutter-width;

        ul {
          float: inherit;
          width: 100%;

          li {
            float: left;
            @include icon('check', 'before');
            width: calc(100% / 3 - #{$gutter-width});
            margin-right: $gutter-width;
            margin: 5px 0;
            @include breakpoint(0 $mobile) {
              width: calc(100% / 2 - #{$gutter-width});
            }

            &:before {
              margin-right: 5px;
              color: $secondary-color;
            }
          }
        }
      }
    }

    #similar-home,
    #recently-watched {
      @include clearfix;

      ul.holiday-homes {

        li {
          a {
            @include breakpoint(0 $mobile) {
              padding: 10px 0;
              border-top: 1px solid $border-color;
            }

            &:hover {
              background: none;

            }
          }
        }
      }

      @include breakpoint($mobile) {
        ul.holiday-homes {
          li {
            &:nth-child(4) {
              display: none !important;
            }

            width: calc(100% / 3);

            a {
              @include breakpoint(0 $mobile) {
                padding: 10px 0;
                border-top: 1px solid $border-color;
              }
            }
          }
        }
      }

    }

    #advantages {
      .group-inner {
        .advantages {
          ul {
            @include icon_list;

            li {

              &.sun {
                @include icon(sun);
              }

              &.group {
                @include icon(users);
              }

              &.bed {
                @include icon(number-of-bedrooms);
              }

              &.child {
                @include icon(child);
              }
            }
          }

        }
      }
    }


  }
}

// Teaser holiday home.
.holiday-home.teaser,
.holiday-home-teaser {
  .sale-icons {
    width: 100%;
    padding-top: 1rem;
    margin-top: 1rem;

    .item {
      width: 100px;
      margin: 0 30px;
      font-size: .8em;

      &:before {
        font-size: 1.6em;
      }
    }
  }

  .price-sale {
    text-align: center;
    font-size: 1.5rem;
    background: $form-background-color;
    padding: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    @include breakpoint(0 $mobile) {
      text-align: left;
      margin: 0;
      padding: 0;
      background: none;
    }
  }

  @include holiday-home-teaser;

  .advantage {
    float: left;
    font-size: 12px;
    margin-left: 15px;
  }

  .price-context {

  }

  .price-info {
    @include price-info;

    .discount-percentage {
      display: none;
    }
  }

  .button {
    width: 100%;
    margin-top: 10px;
  }

}

.right{
  .supplements-wrapper{
    h3{
      font-size:1rem;
    }
    ul{
      margin-bottom:1.5rem !important;
      margin-top:1.5rem !important;
      li{
        display:flex;
        margin:.5rem 0;
        align-items: center;
        svg{
          width:.6em;
          margin-right:.5rem;
        }
      }
    }
  }
}
