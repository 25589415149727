$base-font-color: #212121;
$primary-color: #b5b5b5;
$secondary-color: #434343;
$tertiary-color: #898989;
$heading-font-color: $secondary-color;
$link-color: $primary-color;
$border-color: #dbdbdb;
$overlay-color:$secondary-color;
$grey-color: #AAA;
$dark-grey: #3c3c3c;
$menu-mobile-item-border-color:#222;
$icon-color:#AAA;
$gold: #dc9d00;
$hover-background: #EEE;
$booking-button-color:$secondary-color;
$preselected-booking-button-color:$tertiary-color;

$booking-button-color:#333;
$booking-button-color-hover: darken($booking-button-color, 20);

//$hover-background: transparent;
$search-home-bg-color: #EEE;
$form-background-color: #EEE;
$grey-background-color: #EFEFEF;

$advantage-color: #598527;
$positive-color: green;
$negative-color: red;
$star-reviews-color: #898989;

$done-color:$positive-color;
$todo-color:#CCC;

$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;


$legenda-available-arrival-date-color: $secondary-color;
$legenda-selected-arrival-date-color: $tertiary-color;