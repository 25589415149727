.map-embed {
  margin-bottom: 20px;
  .wrapper {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
    .node-type-city &, .node-type-region & {
      padding-bottom: 37.5%;
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}