@mixin icon_list{
  width: 100%;
  > * {
    list-style: none;
    padding-left: 33px;
    line-height: 26px;
    width: calc(50% - #{$gutter-width} - 10px / 2);
    display:inline-block;
    vertical-align: top;
    margin: 8px 0;
    margin-right:10px;
    &:nth-child(even) {
      margin-left: $gutter-width;
    }
    @include breakpoint(0 $small_mobile){
      width:100%;
      margin-right:0 !important;
      margin-left:0 !important;
    }
    @include breakpoint(0 $tab){
      line-height:20px;
    }

    &:before{
      width:25px;
      display:inline-block;
      color:$icon-color;
      text-align:center;
      margin-right:8px;
      margin-left:-33px;
      font-size:22px;
      vertical-align: top;
    }
  }
}