
/* Navigation */
#main-menu {
  font-family:$heading-font;
  ul {
    line-height: 20px;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
    @include inline-block-list;
    li {
      margin-left: -3px;
      a {
        line-height: 1;
        font-weight:$bold-weight;
        margin: 0 10px;
        font-size: 16px;
        color:$secondary-color;

      }
      a:hover, a.active {
        text-decoration: none;
      }
      &.last {
        border-right: none;
        a {
          margin-right: 5px;
        }
      }
    }
  }
}
