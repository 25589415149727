.block-holiday-homes{
  .node-type-complex & {
    @include breakpoint (0 $mobile) {
      h2 {
        padding: $gutter-width;
      }
      ul {
        border-top: 1px solid $border-color;
        > li {

          border-bottom: 1px solid $border-color;
          @include clearfix;
        }
      }
    }
  }
}