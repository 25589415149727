
/* Breadcrumb and links */
#main .breadcrumb-wrapper {

  background: none !important;
  border: none !important;
  position: relative;
  font-family:$heading-font;
  ul.breadcrumb {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style: none;
      a{
        color:$secondary-color;
        &:hover{
          text-decoration: none;
          color:darken($secondary-color,15);
        }
      }
    }
  }
  .breadcrumb > *:before {
    content: ">";
    margin: 0 7px;
    position: relative;
    top: 0;
  }
  .breadcrumb > *:first-child:before {
    content: " ";
    margin: 0;
  }
  .links {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .code {
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    right: 110px;
    top: 4px;
  }
}
