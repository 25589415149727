// This file contains legacy code that needs to be reorganised.

/* Sprite */
$icons-flag-nl-spacing: 32px;
$icons-flag-de-spacing: 32px;
$icons-list-spacing: 32px;
$icons-list-footer-spacing: 32px;
$icons-eye-spacing: 32px;
$icons-pencil-spacing: 32px;
$icons-read-more-spacing: 32px;
$icons-list-spacing: 64px;



/* Images */
.complex, .column, .node-type-article #block-system-main .image {
  img {
  }
}

.image, .holiday-home, .complex, .column {
  img {
    max-width: 100%;
    height: auto;
  }
}

.slider-thumbs {
  margin: 0 -3px 0 -3px;
  @include clearfix;
  a {
    float: left;
    opacity:.5;
    width: calc(100%/11);
    padding:3px;
    img{
      width:100%;
      height:auto;
    }
    &:hover,
    &.active{
      opacity:1;
    }
  }
}

/* Links */
.link {
  margin-bottom: 20px;
  line-height: 1;
  clear: left;
  float: left;
  i {
    margin-right: 4px;
  }
}

.stars {
  @include stars;
}

.star-reviews, .favorites-link {
  float: left;
  height: 20px;
  line-height: 20px;
}

.star-reviews {
  margin-left: 10px;
  font-size:12px;
}

.favorites-link.with-rating {
  margin-left: 20px;
}

.flag-favorites {
  i.fa {
    margin-right: 4px;
  }
}

.flag-message {
  color: #898989;
  top: 16px;
  font-style: italic;
  margin-left: 19px;
}

/* Icons (trust) */
.recron {
  //  @include icons-sprite(recron, true);
}

.europeesche-verzekeringen {
  //  @include icons-sprite(europeesche-verzekeringen, true);
}

.vvv {
  //  @include icons-sprite(vvv, true);
}

.i-deal {
  //  @include icons-sprite(i-deal, true);
}

.master-card {
  //  @include icons-sprite(master-card, true);
}

.visa {
  // @include icons-sprite(visa, true);
}

.paypal {
  //  @include icons-sprite(paypal, true);
}

/* Pager */
div.item-list {
  ul.pager {
    li {
      a, &.pager-ellipsis, &.pager-current {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}


/*  List pages */
#block-helper-search-holiday-homes, #block-helper-search-complexes {
  .filters {
    h3, a {
      display: inline-block;
      margin-right: 10px;
    }
    a {
      padding: 5px 10px;
      border-radius: 5px, 5px;
      margin-bottom: 15px;
      .erase {
        font-weight: bold;
      }
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.sort {
  .view {
    float: right;
    a {
      &.list {
        border-left: none;
        .icon {
          //       @include icons-sprite(view-list, true);
        }
      }
      &.list.active {
        .icon {
          //     @include icons-sprite(view-list-active, true);
        }
      }
      &.photos {
        .icon {
          //     @include icons-sprite(view-photos, true);
        }
      }
      &.photos.active {
        .icon {
          //     @include icons-sprite(view-photos-active, true);
        }
      }
      &.map {
        .icon {
          //    @include icons-sprite(view-map, true);
        }
      }
      &.map.active {
        .icon {
          //    @include icons-sprite(view-map-active, true);
        }
      }
    }
  }
}

.characteristics {
  ul {
    margin: 0;
    padding: 0;
    float: left;

    li {
     /* &:before {
        font-size: 1.3em;
        margin-right: 7px;
        vertical-align: middle;
        margin-left: -32px;
      }*/
      &.number-of-persons {
        @include icon(number-of-persons);
      }
      &.suitable-for-wheelchairs {
        @include icon(suitable-for-wheelchairs);
      }
      &.number-of-bedrooms {
        @include icon(number-of-bedrooms);
      }
      &.accommodation-type {
        @include icon(accommodation-type);
      }
      &.coast-at-stones-throw {
        @include icon(coast-at-stones-throw);
      }
      &.coast-at-walking-distance {
        @include icon(coast-at-walking-distance);
      }
      &.coast-at-cycling-distance {
        @include icon(coast-at-cycling-distance);
      }

      &.luxury-level {
        @include icon(luxury-level);
      }
      &.sea-view {
        @include icon(sea-view);
      }
      &.child-friendly {
        @include icon(child-friendly);
      }
      &.jacuzzi {
        @include icon(jacuzzi);
      }
      &.private-pool {
        @include icon(private-pool);
      }
      &.sauna {
        @include icon(sauna);
      }
      &.pets-welcome {
        @include icon(pets-welcome);
      }
      &.internet-available {
        @include icon(internet-available);
      }
      &.fireplace {
        @include icon(fireplace);
      }
      &.complex {
        @include icon(complex);
      }
      &.bike-rental {
        @include icon(bike-rental);
      }
      &.swimming-pool {
        @include icon(swimming-pool);
      }
      &.restaurant {
        @include icon(restaurant);
      }
      &.play-ground {
        @include icon(play-ground);
      }
      &.tennis-court {
        @include icon(tennis-court);
      }
      &.playing-field {
        @include icon(playing-field);
      }
      &.wellness {
        @include icon(wellness);
      }
      &.snackbar {
        @include icon(snackbar);
      }
      &.park-shop {
        @include icon(park-shop);
      }
      &.animation-team {
        @include icon(animation-team);
      }
      &.bowling-alley {
        @include icon(bowling-alley);
      }
      &.midgetgolf {
        @include icon(midgetgolf);
      }
      &.golf-course {
        @include icon(golf-course);
      }
      &.fitness {
        @include icon(fitness);
      }
      &.reception {
        @include icon(reception);
      }
    }
  }
}

.list, .view-content {
  .holiday-home {
    a {
      position: relative;
    }
  }
}

.info-icon {
  height:21px;
  width:20px;
  position:relative;
  color:$secondary-color;
  font-size:18px;
  line-height: 1;
  @include icon('info-circled');
}

.title-intro {
  .contact {
    margin-top: 10px;
    a.phone {
      margin-left: 20px;
    }
  }
}

/* Tabs */
.resp-tabs-container, .resp-tabs-list {
  .number-of-reviews {
    display: inline-block;
    width: 26px;
    text-align: center;
    border-radius: 50%;
    right: 7px;
    top: 14px;
    font-weight: normal;
  }
}

.resp-tabs-container {
  .number-of-reviews {
    padding: 4px;
    margin: -4px 0 -4px 5px;
  }
}

.resp-tabs-list {
  li.reviews, li.resp-tab-active.reviews {
    position: relative;
    padding-right: 38px !important;
    .number-of-reviews {
      padding: 2px;
      position: absolute;
    }
  }
  li.reviews:hover, li.resp-tab-active.reviews {
    padding-right: 37px !important;
    .number-of-reviews {
      right: 6px;
      top: 13px;
    }
  }
}

.characteristics-of-trust {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  li {
    padding-top: 10px;
    margin-top: 10px;
    line-height: 38px;
    &:first-child {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }
    i {
      text-align: center;
      width: 40px;
    }
  }
}

/*
.not-front
}*/

#availability.booking-widget, #supplements.supplements-complete .supplements-wrapper { // Old booking widget
  padding: 10px !important;
  h3 {
    margin-top: 0;
  }
}

.offers {
  display: block;
  .offer {
    .wrapper-left {
      em {
        font-style: normal;
        text-transform: lowercase;
        font-variant: small-caps;
      }
    }
    .period, .price {
      padding-top: 7px;
      line-height: 1;
      div {
        float: left;
        margin-right: 5px;
        &.hide-for-small {
          margin-right: 10px;
          display: none; 
        }
      }
    }
  }
}

.offers .discount {
  .price-original {
    line-height: 1;
    height: 12px;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .price {
    display: inline-block;
  }
}

#availability {
  .inline {
    margin-bottom: 10px;
    float: left;
    label, select {
      float: left;
      margin-right: 10px;
      font-weight: normal;
      line-height: 1;
    }
    label {
      padding: 5px 0;
    }
    select {
      border-color: $secondary-color;
      height: 40px;
    }
  }
  form {
    clear: left;
  }
  .flexible {
    clear: left;
    padding: 20px 0 10px 0;
    input[type="text"] {
      margin: 0 10px;
    }
  }
  #period .inline {
    
    label {
      padding-bottom: 0;
    }
    
  }
}

.legenda-block {
  margin-top: 10px;
  .legenda {
    float: left;
    width: 17px;
    height: 17px;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
    &.blocked {
      margin-left: 15px;
      line-height: 14px;
      text-decoration: line-through;
      text-align: center;
    }
    &.selected-arrival-date {
      margin-left: 15px;
    }
    &.available-arrival-date, .selected-arrival-date {
      margin-bottom: 15px;
    }
  }
  span {
    line-height: 17px;
    float: left;
  }
}

.select-day-of-arrival {
  border: 1px solid $border-color;
  padding: 10px 15px;
  font-weight: bold;
  color: $heading-font-color;
  float: left;
}

#supplements {
  min-height: 100px;
  clear: left;
  div {
    h3 {
      margin-top: 20px;
      margin-bottom: 0;
      line-height: 1;
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
      padding: 0;
      margin: 10px 0 -0.5em 0;
    }
    ul {
      margin: 0.5em 0;
    }
    li {
      label {
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        width: 50%;
      }
      span {
        display: inline;
      }
    }
    &.inclusive ul li label {
      width: 100%;
    }
  }
}

/* FAQ page */
.node-type-faq-page #main #block-system-main {
  #entries .wrapper {
    legend {
      //  @include icons-sprite(expanded);
    }
    fieldset.collapsed legend {
      //    @include icons-sprite(collapsed);
    }
  }
}

/* Rental plans */
.node-type-rent-page {
  .field-advantages, .field-rent-faq {
    margin-left: 0 !important;
    li {
      list-style: none !important;
      margin-bottom: 0 !important;
      background: url(#{$base_theme_path}/images/list-advantage-bg.png) left top no-repeat;
    }
  }
  .field-rent-faq {
    margin-left: 0 !important;
    li {
      background: none;
      list-style: none !important;
      margin-bottom: 0 !important;
    }
  }
  .field-rental-plans {
    table {
      tr {
        td {
          span.checked {
            //background: url(#{$base_theme_path}/images/check-bg.png) left top no-repeat;
            @include icon(ok);
            color: $primary-color;
            font-size: 20px;
          }
        }
        &:first-child {
          td:nth-child(3) {
            &:before {
              content: " ";
              position: absolute;
              top: -57px;
              left: 550px;
              z-index: 99;
              html[lang="nl"] & {
                //           @include icons-sprite(most-popular-nl, true);
              }
              html[lang="de"] & {
                //           @include icons-sprite(most-popular-de, true);
              }
            }
          }
        }
      }
    }
  }
}

#block-helper-reviews-homepage, #rent-reviews {
  @include clearfix;
  border-top:none !important;
  width: 100%;
  .review {
    position: relative;
    float: left;
    padding: 10px;
    width: 33.33%;
    .content {
      border-radius: 5px;
      padding: 10px;
      height: 100px;
      &:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 20px;
        top: 109px;
        border: 12px solid;
        z-index: 1;
      }
      &:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 21px;
        top: 109px;
        border: 11px solid;
        z-index: 2;
      }
    }
    .image {
      transform: rotate(-10deg);
      float: left;
      padding: 5px;
      margin: 10px 0 10px 0;
      position: relative;
      z-index: 0;
      /*
      &:before {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width:300px;
        background: #777;
        -webkit-box-shadow: 0 15px 10px #777;
        -moz-box-shadow: 0 15px 10px #777;
        box-shadow: 0 15px 10px #777;
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        transform: rotate(-3deg);
      }
      */
      img {
        vertical-align: bottom;
      }
    }
    .name {
      float: left;
      font-weight: bold;
      margin: 30px 0 0 10px;
      width: 200px;
    }
  }
}


#sidebar-second {
  .term {
    a {
      font-size: 20px;
    }
  }
}


.inactive {
  p {
    color: red;
    font-size: 16px;
    font-weight: bold;
    a {
      color: red;
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

/* YouTube video */
.video-container {
  position: relative;
  padding-bottom: 66.66%;
  /* padding-top: 30px; */
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

/* User login/register */
.block.register {
  h3 {
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  ul {
    padding-bottom: 20px;
    li {
      background: url(#{$base_theme_path}/images/check-bg.png) 0 1px no-repeat;
      padding: 0 0 10px 28px;
    }
  }
}

