
#footer {
  line-height: 1em;
  padding: $gutter-width;
  clear: both;
  padding: 20px 0;
  width: 100%;
  :not(.bottom) .block,
  #block-helper-contact{
    @include breakpoint(0 $tab){
      display:none !important;
    }
  }
  :not(.bottom)  .block {
    float: left;
    width: 25%;
    margin-bottom: 20px;
    @include breakpoint(max-width $large) {
      width: 50%;
    }
    @include breakpoint(max-width $medium) {
      width: 100%;
      padding:0 $gutter-width;
    }
    > ul > li{
      margin-bottom:7px;
      a{
        font-weight:600;
        @include icon(angle-right);
        &:before{
          margin-right:5px;
          display:inline-block;
        }
        &:hover:before{
          text-decoration: none;
        }
      }
    }
  }
  @include breakpoint(max-width $medium) {
    #block-helper-contact.block {
      display: block;
      width: 100%;
      padding-left: $gutter-width;
      h3, .contact, .business-hours, .mail, .faq, .rent {

      }
    }
  }
  .business-hours {
    margin-bottom: 10px;
  }
  .follow {
    margin-top: 10px;
    .youtube,
    .instagram,
    .pinterest,
    .linkedin,
    .google-plus,
    .twitter,
    .facebook{
      font-size:0;
      margin-left:7px;
      &:before{
        font-size:18px;
      }
    }
    .twitter{
      @include icon(twitter);
    }
    .facebook{
      @include icon(facebook);
    }
    .youtube{
      @include icon(youtube);
    }
    .instagram{
      @include icon(instagram);
    }
    .google-plus{
      @include icon(google-plus);
    }
    .pinterest{
      @include icon(pinterest);
    }
    .linkedin{
      @include icon(linkedin);
    }
  }
  .bottom {
    clear: left;
    width: 100%;
    text-align: center;
    ul:not(.contextual-links) {
      display: inline-block
    }
    @include breakpoint(max-width $medium) {
      padding-top: 0;
      padding-left: $gutter-width;
      text-align: left;
    }
    li {
      line-height: 1;
      //vertical-align: middle;
      float: left;
      margin-left: 10px;
      padding-left: 10px;
      &:first-child {
        border: none;
        padding-left: 0;
        margin-left: 0;
      }
      @include breakpoint(max-width $medium) {

        &.hide-for-small {
          display: none;
        }
      }
    }
  }
}

#footer .bottom #block-menu-menu-bottom-menu{
  display:inline-block;
}