
.ui-tooltip {
  .label {
    font-family: $heading-font;
    color: $secondary-color;
    margin: 5px 0;
    font-size: 14px;
    display: block;
    clear: both;
    font-weight: $bold-weight;
  }
  .item {
    display: block;
    font-size: 13px;
  }
}