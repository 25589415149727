@mixin main-characteristics{
  ul {
    @include icon_list;
    li {

      &.number-of-persons {
        @include icon(number-of-persons);
      }
      &.number-of-bedrooms {
        @include icon(number-of-bedrooms);
      }
      &.accommodation-type {
        @include icon(accommodation-type);
      }
      &.suitable-for-wheelchairs {
        @include icon(suitable-for-wheelchairs);
      }
      &.coast-at-stones-throw {
        @include icon(coast-at-stones-throw);
      }
      &.coast-at-walking-distance {
        @include icon(coast-at-walking-distance);
      }
      &.coast-at-cycling-distance {
        @include icon(coast-at-cycling-distance);
      }

      &.luxury-level {
        @include icon(luxury-level);
      }
      &.sea-view {
        @include icon(sea-view);
      }
      &.child-friendly {
        @include icon(child-friendly);
      }
      &.jacuzzi {
        @include icon(jacuzzi);
      }
      &.private-pool {
        @include icon(private-pool);
      }
      &.sauna {
        @include icon(sauna);
      }
      &.pets-welcome {
        @include icon(pets-welcome);
      }
      &.internet-available {
        @include icon(internet-available);
      }
      &.fireplace {
        @include icon(fireplace);
      }
      &.complex {
        @include icon(complex);
      }

      &.bike-rental {
        @include icon(bike-rental);
      }
      &.swimming-pool {
        @include icon(swimming-pool);
      }
      &.restaurant {
        @include icon(restaurant);
      }
      &.play-ground {
        @include icon(play-ground);
      }
      &.tennis-court {
        @include icon(tennis-court);
      }
      &.playing-field {
        @include icon(playing-field);
      }
      &.wellness {
        @include icon(wellness);
      }
      &.snackbar {
        @include icon(snackbar);
      }
      &.park-shop {
        @include icon(park-shop-mall);
      }
      &.animation-team {
        @include icon(animation-team);
      }
      &.bowling-alley {
        @include icon(bowling-alley);
      }
      &.midgetgolf {
        @include icon(golf);
      }
      &.golf-course {
        @include icon(golf);
      }
      &.fitness {
        @include icon(fitness);
      }
      &.reception {
        @include icon(reception);
      }
    }
  }
}