
.menu-small {
  display: none;

  @include breakpoint(0 $mobile) {
    .toggle-menu & {
      display: block;
    }
    #menu-small {
      margin-top: 50px;
      width: calc(100% + #{$gutter-width} * 2);
      margin-left: -$gutter-width;

      ul.links {
        li {
          a {
            display: block;
            padding: $gutter-width/2 $gutter-width;
            width: 100%;
            color: lighten($primary-color, 20);
            font-family: $heading-font;
            border-top: 1px solid $menu-mobile-item-border-color;
            background: $dark-grey;
            font-size: 13px;
            &.active,
            &:hover {
              text-decoration: none;
              color: white;
              background: $primary-color;
            }
            img {
              vertical-align: baseline;
              margin-right: 2px;
            }
          }
        }
        &.inline {
          li {
            a {
              color: white;
              font-size: 16px;
            }

          }
        }
      }
    }
  }
}

#menu-links-small {
  display: none;
  @include breakpoint(max-width $medium) {
    display: block !important;
    width: 100%;
    float: left;
    margin-bottom: 20px;

    border-bottom: 1px solid $border-color;
    li {
      position: relative;
    }
    a {
      float: left;
      clear: left;
      width: 100%;
      padding: 10px $gutter-width/2;
      border-top: 1px solid $border-color;
      font-size: 20px;
      &:hover {
        text-decoration: none;
      }
      @include icon('angle-right', 'after');
      &:after {
        float: right;
      }
    }
  }
}