/* Fix for Firefox */
input::-moz-focus-inner {
  border: 0;
  padding: 0
}

/* Autocomplete throbber */
html.js input.form-autocomplete {
  //background-position: 92% 7px;
  background: none;
}

#autocomplete {
  border: 1px solid $border-color;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  padding: 5px;
  background: #fff;
  border-radius: 2px;
  width: inherit !important;
  ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }
  li {
    background: #fff;
    color: #000;
    cursor: default;
    white-space: pre;
    zoom: 1; /* IE7 */
    &.selected {
      background: darken($border-color, 5%);
    }
  }
  #helper-search-search-content-form & {
    min-width: 224px;
  }
  box-shadow: 0 0 3px #999;
}

/*
input[type="text"], input[type="email"], textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
*/

input.form-text, select, textarea, #from, #to {
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
  *vertical-align: middle;
  padding: 0 10px;
  height: 30px;
  line-height: 40px;
  border-radius: 2px;
  border: 1px solid $border-color;
  box-sizing: border-box;
  &.error {
    border-color: red;
    border-width: 2px;
  }
}

#from, #to {
  width: 100px;
}

.form-item {
  margin-bottom: 10px;
}

.search-empty {
  width: 100%;
  margin-top: 20px;
  p {
    font-size: 16px;
    font-weight: bold;
  }
}

/* Text search form */
.page-search {
  #search-form {
    margin-bottom: 30px;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin: 20px 0 10px 0;
  }
}

/* Holiday homes search form */
#block-helper-search-search-holiday-homes {
  padding: $gutter-width;
  .front & {
    @include clearfix;
    h2 {
      font-size: 24px;
      margin-right: 30px;
      line-height: 30px;
      .more-than {
        color: $tertiary-color;
      }
    }
    .form-item {
      float: left;
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 18px;
      @include breakpoint (0 $mobile) {
        width: 100%;
      }
    }
    input.form-submit {
      float: right;
      width: calc(50% - 10px);
      margin-right: 10px;
      @include breakpoint (0 $mobile) {
        width: 100%;
        margin-right: 0;
      }
    }
    /*
    .form-item-destination {
      width: 336px;
    }
    */
    input[type="text"], select, label {
      width: 100%;
      *padding: 0 !important;
      *width: 184px !important;
      *border: none !important;
    }
  }
  .not-front & {
    h2 {
      font-size: 22px;
    }
    background: $form-background-color;
    .form-item {
      width: 100%;
    }
    input[type="text"], select, label {
      width: 100%;
    }
  }
}

/* Holiday homes sort form */
#helper-search-holiday-homes-sort-form {
  .form-item {
    float: left;
    margin-bottom: 0;
  }
  label, .sod_select {
    float: left;
  }
  label {
    line-height: 33px;
    margin-right: 10px;
  }
  .sod_select {
    width: 180px;
  }
}

/* Newsletter sign up */
#block-helper-newsletter-sign-up {
  input[type="email"] {
    width: 190px;
  }
}

.ui-dialog {
  background: #fff;
  @include breakpoint(0 $mobile){
    margin:10px;
    width:calc(100% - 20px) !important;
  }
  padding: 10px;
  .ui-dialog-content.ui-widget-content {
    padding: 0 10px;
    border: none;
    background: none;
  }
  .ui-dialog-titlebar.ui-widget-header {
    border-radius: 0;
    border: none;
    background: $form-background-color;
  }
  .ui-dialog-buttonpane.ui-widget-content {
    padding: 0;
    margin: 0 10px 0 0;
    border: none;
    button {
      margin: 0 0 0 10px;
    }
  }
  button.ui-button {
    @include button-general;
    font-weight: normal;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    span {
      padding: 0;
    }
  }
}

#dialog-form {
  margin-top: 10px;
  input.form-text, select, textarea, #from, #to {
    height: 32px;
    line-height: 32px;
  }
  div.messages {
    margin: 0 0 8px 0;
    padding-left: 40px;
    ul {
      margin: 0;
    }
  }
  @include breakpoint($mobile) {
    .field-type-text, .form-type-textfield, .field-type-list-text, .field-type-list-integer, .form-type-select, .form-type-textarea {
      float: left;
      margin-bottom: 5px;
    }
    #edit-field-salutation, #edit-field-zip-code, #edit-field-street {
      clear: left;
    }
    .form-type-select {
      width: 131px;
    }
    .form-item-field-from, .form-item-field-to {
      width: 181px;
      margin-bottom: 10px;
    }
    .form-item-field-from {
      margin-right: 15px;
    }
    #edit-field-salutation, #edit-field-newsletter {
      width: 90px;

      .form-type-select, select {
        width: 130px;
      }
    }
    #edit-field-initials, #edit-field-zip-code {
      width: 80px;
      margin-right: 15px;
      clear: left;
    }
    #edit-field-surname {
      width: 135px;
      margin-right: 15px;
    }
    #edit-field-booking-mail, #edit-field-street {
      width: 230px;
      margin-right: 15px;
      clear: left;
    }
    #edit-field-house-number {
      width: 80px;
    }
    #edit-field-city {
      width: 140px;
      margin-right: 15px;
    }
    .field-type-text label, .form-type-textfield label, .form-type-select label, label[for="edit-field-newsletter-und"], .form-type-textarea label {
      font-weight: bold;
      font-size: 13px;

      html[lang="en"] & {
        font-size: 12px;
      }
    }
  }
  input.form-text, select {
    width: 100%;
  }
  textarea {
    resize: none;
    height: 120px;
  }

}

// Review form.
.page-node-add-review {
  h1 {
    margin-bottom: 30px;
  }
  h2 {
    clear: left;
  }
  .holiday-home {
    .image, .title {

    }
    .title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
}

#user-login, #user-pass, #user-register-form, #user-profile-form {
  label {
    font-weight: bold;
    display: block;
  }
  .form-type-checkbox {
    input, label {
      display: inline-block;
    }
    label {
      font-weight: normal;
      margin-left: 5px;
    }
  }

  .description {
    font-style: italic;
    font-size: 12px;
  }
  #edit-actions {
    margin-bottom: 15px;
  }
}

#user-pass {
  min-height: 400px;
}

.profile {
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
  margin-bottom: 10px;
  h3 {
    border-bottom-color: $border-color;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  label {
    font-weight: bold;
    margin-right: 10px;
  }
}

#views-form-user-tabs-favorites-on-user-page {
  legend {
    display: none;
  }
  fieldset.form-wrapper {
    position: relative;
  }
}

.form-actions > *{
  margin-right:.5rem;
  &:last-child{
    margin-right:0;
  }
}
