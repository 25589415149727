@mixin reviews {
  .add, .ratings {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .average-rating-wrapper {
    .average-rating {
      font-weight: bold;
      text-align: center;
      border-radius: 5px;
    }
  }
  @include breakpoint($tab) {
    @include clearfix;
    .overall {
      margin: 40px 0;
      float: right;
      width: 20%;
      text-align: center;
      .average-rating {
        display: block;
        width: 100%;
        font-size: 30px;
        padding: 30px !important;
        margin: 10px 0;
      }
      .rating-phrase,
      .average-rating-desc-before {
        font-family: $heading-font;
        color: $secondary-color;
        font-size: 18px;
        font-weight: $bold-weight;
      }
      .average-rating-desc-after {
        display: none;
      }
    }
    .averages {
      width: calc(70%);
      float: left;
    }
  }
  @include breakpoint(0 $tab) {
    .average-rating-wrapper {
      @include clearfix;
      margin-bottom: $gutter-width;

      .average-rating-desc-after {
        float: left;
        margin: 24px 10px;
        color: $secondary-color;
        font-family: $heading-font;
        font-weight: $bold-weight;
        font-size: 16px;
      }
      .average-rating-desc-before,
      .rating-phrase {
        display: none;
      }
      .average-rating {
        float: left;
        line-height: 1;
        padding: 15px 20px;
        font-size: 24px;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
  .ratings {
    .averages {
      span {
        display: inline-block;
      }
      .outer {
        width: calc(100% - 35px);
        border-radius: 5px;
        margin: 5px 10px 5px 0;
      }
      .label {
        display: block;
        padding: 3px 0;
        border-radius: 5px;
        text-indent: 8px;
        font-weight: bold;
      }
      .value {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .review {
    float: left;
    clear: left;
    margin-bottom: 20px;
    padding-bottom: 10px;
    width: 100%;
    .content {
      h4 {

        width: calc(100% - 80px);
        float: left;
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 15px;
      }
      .period-of-stay-and-name {
        width: calc(100% - 80px);
        clear: left;
        margin-bottom: 10px;
      }
      .icon {
        float: left;
        font-size:16px;
        &:before {
          color: $secondary-color;
        }
      }
      .positive .icon {
        @include icon('plus-circled');
      }
      .negative .icon {
        @include icon('minus-circled');
      }
      .positive, .negative {
        @include breakpoint($tab) {
          width: calc(100% - 80px);
        }
        p {
          margin-left: 30px;
        }
      }
      .comment-owner {
        margin-top: 20px;
        margin-left: 30px;
      }
      .average-rating {
        float: right;
        border-radius: 2px;
        padding: 10px 20px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;
        @include breakpoint($tab) {
          margin-top: 70px;
        }
      }
    }
  }
}