
.resp-tabs-list li {
  font-weight: bold;  
  display: inline-block;
  padding: 16px 15px 10px 15px;
  margin: 0 0 0 20px;
  list-style: none;
  cursor: pointer;
  float: left;
  position: relative;
  z-index: 10;
}

.resp-tabs-container {
  padding: 0px;
  clear: left;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
}

.resp-tab-content {
  position: absolute !important;
  max-width: $max_width;
  left: -10000px !important;
  display: block !important;  //display: none;
  padding: 20px 0;
  margin-bottom: 20px;
}

.resp-tabs-list li.resp-tab-active, .resp-tabs-list li:hover {
  border-bottom: none;
  margin-bottom: -1px !important;
  padding: 15px 14px 11px 14px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold; 
}

div.resp-tab-content.resp-tab-content-active {
  position: relative !important;
  left: 0 !important;
}

h2.resp-accordion {
  font-family: Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  padding: 15px $gutter-width;
}

h2.resp-tab-active {
  margin-bottom: 0px !important;
  padding: 15px $gutter-width !important;
}

/*-----------Vertical tabs-----------*/
.resp-vtabs ul.resp-tabs-list {
  float: left;
  width: 30%;
}

.resp-vtabs .resp-tabs-list li {
  display: block;
  padding: 15px 15px !important;
  margin: 0;
  cursor: pointer;
  float: none;
}

.resp-vtabs .resp-tabs-container {
  padding: 0px;
  float: left;
  width: 68%;
  min-height: 250px;
  border-radius: 4px;
  clear: none;
}

.resp-vtabs .resp-tab-content {
  border: none;
}

.resp-vtabs li.resp-tab-active {
  border-right: none;
  position: relative;
  z-index: 1;
  margin-right: -1px !important;
  padding: 14px 15px 15px 14px !important;
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

h2.resp-tab-active span.resp-arrow {
  border: none;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

/*-----------Accordion styles-----------*/
.resp-easy-accordion  h2.resp-accordion {
  display: block;
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
}

@media only screen and (max-width: $large) {
  .resp-tab-content {
    padding: 20px $gutter-width;
    margin-bottom: 0;
  }

  ul.resp-tabs-list {
    display: none;
  }

  h2.resp-accordion {
    display: block;
  }

  .resp-vtabs .resp-tabs-container {
    border: none;
    float: none;
    width: 100%;
    min-height: initial;
    clear: none;
  }

  .resp-accordion-closed {
    display:none !important;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid $border-color !important;
  }
}