$booking-step-sidebar: 440px;
$booking-step-margin: 20px;
$booking-step-margin-mobile: 7px;
$booking-funnel-line-color: rgba(black, .1);
$step-background: lighten($border-color, 5%);
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.page-book {
  #helper-search-block-content-form{
    display:none;
  }
  #block-helper-recently-watched-4,
  #block-helper-similar-holiday-homes {
    display: none;
  }
  #block-system-main {
    position: relative;
  }
}
#booking-funnel{
  .messages{
    margin-bottom:2rem;
  }
}
#main .helper-book-funnel {

  .card-wrapper {
    margin-bottom: 2rem;
    //box-shadow: rgba(black, .5) 0 0 2px;
    border: 1px solid $border-color;
    background: white;
    padding: 2rem;

    border-radius: 5px;

    input[name="stripe_cardholder_name"] {
      width: 100%;
      padding: 15px !important;
      line-height: 1em !important;
    }

    input::placeholder {
      color: #666 !important;
    }

    #card-element {
      padding: 15px;
      border: 1px solid $border-color;
      background: white;
      border-radius: 5px;
    }
  }
  @include breakpoint(0 $mobile){
    padding-left:10px;
    padding-right:10px;
  }
  .ajax-progress {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
  }
  margin-top: 20px;
  .previous {
    border-top: none !important;
    input[type="submit"],
    a {
      background: none;
      font-weight: normal;
      padding: 0;
      font-size: 14px;
      border: none;
      border-radius: 0;
      text-shadow: none;
      box-shadow: none;
      color: #666;
      text-decoration: underline;
    }
  }
  h1 {
    margin: 20px 0;
    width: 100%;
    float: none;
  }
  margin-bottom: 50px;
  @include clearfix;
  .booking {
    float: left;
    width: calc(100% - #{$booking-step-sidebar} - #{$booking-step-margin});
    #steps-wrapper {
      .ajax-loading--helper-flean-manager-validate-form & {

        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          left: 0;
          background: rgba(white, .5);
          z-index: 99;
        }
        &:after {
          content: '\E832';
          font-family: 'icons';
          position: absolute;
          font-size: 2em;
          top: calc(50% - .5em);
          left: calc(50% - .5em);
          animation: spin .7s infinite;
          z-index: 100;
        }
      }

      @include breakpoint(0 $mobile){
        input{
          float:none;
        }
        .reliability-icons{
          @include clearfix;
          margin:20px 0;
          float:none;
        }
      }
      legend,
      .fieldset-legend {
        display: block;
        width: calc(100% - #{$booking-step-margin});
        @include breakpoint(0 $mobile){
          width: calc(100% - #{$booking-step-margin-mobile});
        }
      }

      > * > fieldset,
      > fieldset {
        background: $step-background;
        padding: 20px;

        padding-top: 80px;
        @include breakpoint(0 $mobile) {
          padding: $booking-step-margin-mobile;
          padding-top: 50px;
        }
        position: relative;
        margin-bottom: $booking-step-margin;
        > legend {
          position: absolute;
          top: $booking-step-margin;
          left: $booking-step-margin;
          @include breakpoint(0 $mobile) {
            top: $booking-step-margin-mobile;
            left: $booking-step-margin-mobile;
          }
          h2 {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px solid $border-color;
            width: 100%;
            margin-bottom: 10px;
          }
        }

        border-radius: 5px;

        legend,
        label {
          line-height: 32px;
          font-weight: bold;
        }
        .fieldset-description,
        .description {
          font-style: italic;
          font-size: 12px;
          color: #aaa;
          line-height: normal;
        }
        select {
          background: white;
          min-width: 60px;
          line-height: 50px;
        }

        input[type="checkbox"] {
          display: none;
          ~ label {
            float: left;
            font-weight: bold;
            width: 60%;
            cursor: pointer;
            &:after {
              content: ' ';
              background: white;
              width: 20px;
              height: 20px;
              border: 1px solid darken($border-color,10);
              border-radius: 2px;
              line-height: 20px;
              padding-left: 2px;
              font-size: 13px;
              color: $secondary-color;
              cursor: pointer;
              font-family: 'icons';
            }
          }
          &:checked ~ label:after {
            content: '\E826';

          }
        }
        input[type="radio"] {
          display: none;
          ~ label {
            font-weight: bold;
            width: 60%;
            cursor: pointer;
            position: relative;
            padding-left: 30px;
            &:after,
            &:before {
              top: -3px;
              content: ' ';
              background: white;
              width: 20px;
              position: absolute;
              left: 0;
              height: 20px;
              border: 1px solid darken($border-color, 10);
              border-radius: 50%;
              display: inline-block;
              cursor: pointer;
              padding-left: 2px;
              font-size: 13px;
              color: $secondary-color;
              font-family: 'icons';
              box-shadow: inset 0px 2px 3px rgba(black, .2);
              margin-right: 10px;
              box-sizing: border-box;
            }
          }
          &:after {
            display: None;
          }
          &:checked ~ label:after {
            background: $secondary-color;
            height: 10px;
            width: 10px;
            margin: 5px;
          }
        }
        .form-item {
          float: none;
          width: auto;
          @include clearfix;
          label {
            span {
              width: auto;
              float: none;
            }
          }
          textarea {
           height:auto;
            margin-bottom: 5px;
          }
          input[type="text"], input[type="number"], input[type="tel"], input[type="email"] {
            line-height: 30px;
            padding-left: 10px;
            margin-bottom: 5px;
            height:40px;
            &::placeholder {
              color: #aaaaaa;
            }
          }
        }
        &#edit-booking-type {
          .form-radios {
            margin-top: 20px;
            .form-item {
              display: inline-block;
              width: calc(50% - 3px);
              @include breakpoint (0 $mobile){
                width:100%;
              }
              label {
                width: 100%;
                font-weight: normal;
                line-height: normal;
              }
            }
          }
        }
        &#edit-company {
          .form-item label {
            width: 40%;
            font-weight: normal;
            @include breakpoint(0 $mobile){
              width: calc(100% - 60px);
            }
          }
        }
        &#edit-supplements {
          .form-type-select,
          .form-type-checkbox {
            position: relative;
            float: none;
            width: 70%;
            @include breakpoint(0 $mobile){
              width:calc(100% - 40px);
            }
            .field-suffix {
              float: none;
              @include breakpoint(0 $mobile){
                line-height: 1;
                max-width:40px;
                display:inline-block;
              }
            }
            select {
              position: absolute;
              right: -40px;
            }
            input[type="checkbox"] {
              ~ label {
                &:after {
                  position: absolute;
                  right: 0;
                  top: 3px;
                }
              }
            }
          }
        }
        &#edit-payment,
        &#edit-details {
          div.messages{
            float:none;
          }
          input[type="submit"]{
            font-size: 1.05em;
            padding: 0 45px 0 36px;
          }
          @include breakpoint(0 $mobile){
            legend{
              width: calc(100% - #{$booking-step-margin-mobile}*2);
            }
          }
          .form-type-radios {
            label {
              display: block;
              float: none;
            }
            .form-type-radio {
              float: left;
              width: auto;
              white-space: nowrap;
              margin-right: 70px;
              @include breakpoint(0 $mobile){
                margin-right:0;
                width:100%;
              }
              input {
                float: left;
                margin-right: 10px;
              }
              label {
                float: none;
                font-weight: normal;
                line-height: normal;
              }
            }
          }
          .form-item-agree,
          .form-item-newsletter {
            position: relative;
            margin-top: 10px;
            line-height: 1;
            label {
              padding-left: 30px;
              position: relative;
              font-weight: normal;
              width: 100%;
            }
            label:after {
              position: absolute;
              left: 0;
              top: 6px;
            }
          }
          .flex {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint(0 $mobile){

              display:block;
              input{
                width:100% !important;
              }
            }
            @include clearfix;
            select {
              height: 40px;
              width: 100%;
            }
            > fieldset,
            > .form-item {
              width: calc(50% - 20px);
              @include breakpoint(0 $mobile) {
                width: 100%;
              }
              margin-bottom: 10px;
              &:nth-child(odd) {
                margin-right: 20px;
              }
            }
            fieldset .fieldset-wrapper{
              .fieldset-description{
                width:100%;
              }
              margin-bottom: 0;
              display:flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .form-item.form-type-select{
                flex: 3;
              }
              .form-item {
                flex: 2;
                margin-right: 10px;
                margin-bottom: 0px;
                &:nth-child(3n) {
                  margin-right: 0px;
                }

                select,
                input {
                  width: 100%;
                }
              }
            }
          }
          .agree {
            @include breakpoint(0 $mobile){
              margin:30px 0;
            }
            .form-item {
              margin-top: 0;
              a {
                text-decoration: underline;
              }
            }
          }
          .submit {
            @include clearfix;
            input {
              float: left;

            }


          }
        }
      }
    }
  }
  .reliability-icons {
    float: right;
    div {
      float: left;
      margin: 0 4px;
    }
  }
  #overview-anchor {
    float: right;
  }
  .overview-wrapper {
    h3 {
      border-top: none !important;
    }
    @include breakpoint(1120px) {
      .sticky-overview & {

        position: fixed;
        top: 20px;
        width: 1120px;
        padding-right: 20px;
        height: 0;
        .overview-inner {
          float: right;
        }
      }
    }

    float: right;
    .overview {
      .updating-overview & {

        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          left: 0;
          background: rgba(white, .5);
          z-index: 99;
        }
        &:after {
          content: '\E832';
          font-family: 'icons';
          position: absolute;
          font-size: 2em;
          top: calc(50% - .5em);
          left: calc(50% - .5em);
          animation: spin .7s infinite;
          z-index: 100;
        }
      }
      width: $booking-step-sidebar;
      box-shadow: 0 0 5px rgba(black, .2);
      border-radius: 5px;
      .accommodation {
        padding: $booking-step-margin;
        border-bottom: 1px solid $booking-funnel-line-color;
        position: relative;
        @include clearfix;
        .left {
          width: 140px;
          margin-right: 10px;
          float: left;
          img {
            width: 100%;
            border-radius: 5px;
            height: auto;
          }
        }
        h3{
          margin-bottom:15px;
        }
        .rating {
          bottom: $booking-step-margin;
          left: calc(140px + #{$booking-step-margin} + 10px);
          .amount {
            padding: 4px 8px;
            margin-right: 5px;
            border-radius: 5px;
            background: $primary-color;
            font-weight: bold;

            color: white;
            display: inline-block;
          }
        }
      }
      .booking-details {
        padding: $booking-step-margin;
        border-bottom: 1px solid $booking-funnel-line-color;
        font-size: 1.3em;
        color: #666;
        > div {
          padding-left: 40px;
          display: block;
          position: relative;
          line-height: 35px;
          &:before {
            width: 30px;
            text-align: center;
            position: absolute;
            font-size: 1.4em;
            top: 0;
            left: $booking-step-margin;
            margin-left: -20px;
            font-family: icons;
          }
          &.persons {
            &:before {
              content: '\E818';
            }
          }
          &.dates {
            &:before {
              font-size: 1.1em;
              content: '\F133';
            }
            > div {
              display: inline-block;
            }
            .separator {
              margin: 0 10px;
              &:before {
                content: '\E836';
                font-family: 'icons';
              }
            }
          }
          &.nights {
            &:before {
              font-size: 1.2em;
              content: '\f186';
            }
          }
        }
      }
      .costs {

        #cost-overview {

          h3 {
            margin-bottom: 10px;
          }
          @include clearfix;
        }
        padding: $booking-step-margin;
        .subtotal {
          border-top: 1px solid $booking-funnel-line-color;
        }
        .total-cost {
          border-top: 1px solid $booking-funnel-line-color;
        }
        .particulars {
          margin-top: $booking-step-margin;
          @include clearfix;
          color: #666;
        }
      }
    }
    .helpdesk {
      margin-top: $booking-step-margin;
      font-size: 1.2em;
      .left {
        width: 60px;
        float: left;
        margin-right: 20px;
        img {
          border-radius: 50%;
          width: 100%;
          height: auto;
          box-shadow: 0px 2px 3px rgba(black, .2);
        }
      }
      .main {
        padding-top: 8px;
        p {
          margin: 0;
        }
      }
      .phone {
        position: relative;
        display: inline-block;
        padding-left: 20px;
        p,
        div {
          margin: 0;
          display: inline-block;
        }
        &:before {
          content: '\E802';
          font-family: 'icons';
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .post-booking-instructions {
    margin: $booking-step-margin;
    font-size: 1.2em;
    ul {
      li {
        padding-left: 40px;
        &:before {
          content: '\e826';
          font-size: 1.3em;
          margin-left: -30px;
          margin-right: 10px;
          color: green;
          font-family: 'icons';
        }
      }
    }
  }

  @include breakpoint (0 1100px) {
    .overview-wrapper,
    .booking {
      width: 100%;
      float: none;
    }
    .overview-wrapper .overview {
      width: 100%;
    }
  }
}
