
$gutter-width: 20px;

$sidebar-width: 360px;
$photo_viewer_main_photo_proportion:2/3;
$full_photo_viewer_margin:80px;
$review_slide_height:120px;
$max_width: 1140px;
$max_logo_width:220px;
$subnavigation-height:60px;


$small_mobile: 420px;
$mobile: 980px;
$tab: 1024px;
$wide: 1400px;

$medium: $mobile;
$large: $tab;