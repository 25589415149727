.photo-viewer {
  margin-bottom: $gutter-width;
  @include clearfix;
  position: relative;
  .main-photo {
    .main-photo-inner {
      height: 0;
      width: 100%;
      padding-bottom: calc(100% * #{$photo_viewer_main_photo_proportion});
    }
    @include breakpoint(0 $mobile) {
      display: none;
      .main-photo-inner {
        margin-bottom: $gutter-width;
      }
    }
    @include breakpoint($mobile) {
      width: calc(100% - #{$gutter-width} - #{$sidebar-width});
      float: left;
    }
  }
  .sidebar {
    @include breakpoint(0 $mobile) {
     display:none;
    }
    @include breakpoint($mobile) {
      position: absolute;
      right: 0;
      top:0;
      width: $sidebar-width;
      margin-left: $gutter-width;
      height: 100%;
      .second-box {
        height: calc(100% / 2 - #{$gutter-width} / 2);
        margin-bottom: $gutter-width;
        .review-slider-wrapper,
        .reviews {
          height: 100%;
          display: block;
        }
        .photo {
          width: 100%;
          height: 100%;
        }
      }
      .third-box {
        @include clearfix;
        display: block;
        height: calc(100% / 2 - #{$gutter-width} / 2);
        .photo {
          display: block;
          height: calc(100% / 2 - #{$gutter-width} / 2);
          float: left;
          width: calc(100% / 2 - #{$gutter-width} / 2);
          &:first-child,
          &:nth-child(2) {
            margin-bottom: $gutter-width;
          }
          &:nth-child(2),
          &:nth-child(4) {
            margin-left: $gutter-width;
          }
        }
      }
    }
  }
  iframe#player {
    width: 100%;
    height: 100%;
  }
  .photo {
    background-size: cover;
    position: relative;
    cursor: pointer;
    background-position: 50% 50%;
    &.youtube {
      cursor: pointer;
      @include icon(play);
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
        line-height: 1;
        color: $secondary-color;
        background: rgba(white, .5);
        font-size: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 11px 14px;
      }
    }
    &.link-to-all {
      cursor: pointer;
      .text {
        width: 100%;
        height: 100%;
        display: table;
        position: absolute;
        background: rgba($overlay-color, .7);
        color: white;
        &:hover {
          background: $overlay-color;
        }
        .inner {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          width: 100%;
          font-size: 20px;
          @include icon(angle-right, 'after');
          &:after {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .full-photo-slider {
    @include breakpoint($mobile) {
      overflow: hidden;
      position: fixed;
      top: 0;
      height: 0;
      left: 0;
      background: white;
      width: 100%;
      z-index: 9999999;
      .inner {
        height: calc(100% - #{$full_photo_viewer_margin} * 2);
        width: calc(100% - #{$full_photo_viewer_margin} * 2);
        margin: $full_photo_viewer_margin;
      }
      .close {
        position: absolute;
        top: $gutter-width - 5px;
        right: $gutter-width;
        @include icon(cancel);
        z-index: 999;
        font-size: 30px;
        cursor: pointer;
        vertical-align: top;
        line-height: 1;
        &:before {
          color: darken($border-color,20);
        }
        &:hover:before {
          color: black;
        }
      }
    }

    .inner {
      @include breakpoint (0 $tab) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        height: calc(100% - #{$full_photo_viewer_margin});
        width: calc(100% - #{$full_photo_viewer_margin});
        margin: $full_photo_viewer_margin/2 $full_photo_viewer_margin/2;
      }

      @include breakpoint((0 $tab) (orientation landscape)) {
        width: calc(100% - 100px);
        margin: 20px 50px;
        height: calc(100% - 40px);
      }
      @include breakpoint(0 $mobile) {
        margin: 0;
        width: 100%;
      }
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
          iframe,
          .photo {
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
          }
          iframe {
            @include breakpoint((0 $small_mobile) (orientation portrait)) {
              padding: 4% 0;
            }
            @include breakpoint(($small_mobile $mobile) (orientation portrait)) {
              padding: 2% 0;
            }
            @include breakpoint((0 $tab) (orientation landscape)) {
              padding: 1% 0;
            }
          }
        }
      }
      .slick-next {
        right: -$full_photo_viewer_margin;
        @include icon(angle-right);
        @include breakpoint(0 $tab) {
          right: - $full_photo_viewer_margin/2;
        }
      }
      .slick-prev {
        left: -$full_photo_viewer_margin;
        @include icon(angle-left);
        @include breakpoint(0 $tab) {
          left: - $full_photo_viewer_margin/2;
        }
      }
      .slick-next,
      .slick-prev {
        @include breakpoint(0 $tab) {
          width: 40px;
        }
        &:before {
          color: darken($border-color,20);
        }
        &:hover {
          &:before {
            color: black;
          }
        }
      }

    }
    .pager {
      position: absolute;
      bottom: $gutter-width;
      left: $gutter-width;
      z-index: 999;
      font-size: 14px;
      color: darken($border-color,20);
    }
  }
}

body.full-photo-slider-active {
  @include breakpoint($mobile) {
    overflow: hidden;
    #main {
      height: 0;
      overflow: hidden;
    }
    .full-photo-slider {
      height: 100%;
    }
  }
}

@include breakpoint(0 $mobile) {
  .photo-viewer {
    .full-photo-slider {
      position:relative;
      width: calc(100% + 20px);
      margin-left:-10px;
      padding-bottom:70%;
      .inner {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        .slick-list .slick-track .video,
        .slick-list .slick-track .photo {
          height: 100%;
          background-size: cover;
          position: relative;
          iframe {
            position: absolute;
            height: 100%;
            width: 100%;

          }
        }
        .slick-next {
          right: 0;
          &:hover:before {
            color: white;
          }
        }
        .slick-prev {
          left: 0px;
          &:hover:before {
            color: white;
          }
        }
        .slick-list .slick-track iframe {
          padding: 0;
        }
      }
      .pager {
        display: none;
      }
    }
  }
}

.reviews-mobile{
  display:none;
  @include breakpoint(0 $mobile){
    display:block;
    .review-slider-wrapper{
      height:180px;
      margin-bottom:$gutter-width;
    }
  }
}