@font-face {
  font-family: 'icons';
  src: url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.eot?1548072045');
  src: url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.eot?1548072045#iefix') format('embedded-opentype'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.woff2?1548072045') format('woff2'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.woff?1548072045') format('woff'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.ttf?1548072045') format('truetype'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.svg?1548072045#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

$facebook: '\E800';
$mail: '\E801';
$phone: '\E802';
$website: '\E803';
$location: '\E804';
$address: '\E805';

$icons: (
        icon-facebook:'\e800',
        icon-mail: "\e801",
        icon-phone:"\e802",
        icon-link-ext:"\e803",
        icon-location:"\e804",
        icon-address:"\e805",
        icon-users:"\e806",
        icon-info-circled:"\e807",
        icon-arrow-combo:"\e808",
        icon-coast-at-walking-distance:"\e809",
        icon-coast-at-stones-throw:"\e80a",
        icon-bowling-alley:"\e80b",
        icon-bike-rental:"\e80c",
        icon-child-friendly:"\e80d",
        icon-coast-at-cycling-distance:"\e80e",
        icon-swimming-pool:"\e80f",
        icon-animation-team:"\e810",
        icon-fireplace:"\e811",
        icon-complex:"\e812",
        icon-internet-available:"\e813",
        icon-jacuzzi:"\e814",
        icon-wellness: "\e814",
        icon-snackbar:"\e815",
        icon-luxery-level:"\e816",
        icon-number-of-bedrooms:"\e817",
        icon-number-of-persons:"\e818",
        icon-park-shop-mall:"\e819",
        icon-pets-welcome:"\e81a",
        icon-playing-field:"\e81b",
        icon-play-ground: "\e81B",
        icon-private-pool:"\e81c",
        icon-restaurant:"\e81d",
        icon-sea-view:"\e81e",
        icon-tennis-court:"\e81f",
        icon-accommodation-type:"\e820",
        icon-sauna: "\e821",
        icon-spin3:"\e832",
        icon-angle-left:"\f104",
        icon-angle-right:"\f105",
        icon-angle-down:"\F107",
        icon-angle-up: "\F106",
        icon-right-open: "\E82F",
        icon-calendar-empty:"\f133",
        icon-cancel:"\E822",
        icon-play:"\E823",
        icon-plus:"\E824",
        icon-plus-circled: "\E82E",
        icon-minus:"\E825",
        icon-minus-circled: "\E830",
        icon-check:"\E826",
        icon-sun: "\F185",
        icon-child: "\F1AE",
        icon-facebook-official: "\F230",
        icon-whatsapp: "\F232",
        icon-twitter-squared: "\F230",
        icon-mastercard: "\F329",
        icon-bank: "\F19C",
        icon-thumbs-up: "\F164",
        icon-heart-empty: "\E82C",
        icon-heart: "\E82B",
        icon-creditcard: "\E82A",
        icon-lock: "\E829",
        icon-star: '\E827',
        icon-star-empty: '\E828',
        icon-star-half-alt: '\F123',
        icon-luxury-level: '\E827',
        icon-search: '\E82D',
        icon-menu: '\F0C9',
        icon-twitter: '\F099',
        icon-instagram: '\F16D',
        icon-google-plus: '\F0D5',
        icon-pinterest: '\F231',
        icon-linkedin: '\F0E1',
        icon-fitness: '\E833',
        icon-reception: '\F0F3',
        icon-golf: '\E831',
        icon-youtube: '\F167',
        icon-suitable-for-wheelchairs: '\F193',
        icon-ok: '\E826',
        icon-bricks: '\E837',
        icon-door: '\E838',
        icon-surface: '\E839',
        icon-plot-area:'\E83A',
        icon-download: '\E83B'
);

@mixin icon($id, $position : 'before') {
  &:#{$position} {
    font-family: 'icons';
    content: map_get($icons, "icon-" + $id);
    font-weight: normal;
  }
}

@function icon_code($id){
  @return map_get($icons, "icon-"+$id)
}