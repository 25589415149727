@mixin ng-button {
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  border: 1px solid $primary-color;
  border-radius: 2px;
  padding: 12px 4px;
  background: white;
  position: relative;
  margin: 10px 0;
  outline: none;
  &:active {
    outline: none;
  }
  &:hover {
    background: $primary-color;
    color: white;
  }
  &:after,
  &:before {
    font-family: 'icons';
    display: inline-block;
    margin: 0 10px;
  }
}

@mixin ng-widget {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border: 1px solid $primary-color;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
  padding: 80px 20px 20px 20px;
}

$option-border-color: #eee;
$option-border-color-hover: #ddd;

.booking-widget {

  app-booking-widget {

    .day-names {
      td {
        text-align: center;
        color: $grey-color;
        font-size: 11px;
      }
    }
    .loader {
      background: rgba(255, 255, 255, .7);
      border-radius: 2px;
      position: absolute;
      top: 0px;
      font-size: 0;
      left: 0px;
      border-radius: 2px;
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      z-index: 999999;
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        font-family: 'icons';
        content: '\E832';
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-left: -10px;
        margin-top: -10px;
        @include loader-spin;
      }
    }

    .datepicker-widget {
      display: block;
      position: relative;
      margin-left: -$gutter-width;
      padding: 9px $gutter-width;
      @include breakpoint(0 $mobile) {
        padding: 9px $gutter-width/2;
        margin-left: -$gutter-width/2;
        width: calc(100% + #{$gutter-width});
      }
      background: darken($search-home-bg-color, 2);
      width: calc(100% + #{$gutter-width} * 2);
      transition: color .1s ease;
      margin-bottom: $gutter-width;
      .fixed-booking-widget & {
        background: darken($primary-color, 5);
        height: $subnavigation-height;
      }
      > button {
        @include ng-button;
        padding: 8px 4px;
        .fixed-booking-widget & {
          border: none;
        }
        text-align: left;
        margin: 0;
        padding-left: 50px;
        line-height: 1.1;
        &:before {
          content: '\F133';
          position: absolute;
          left: 6px;
          font-weight: 200;
          top: 10px;
          font-size: 20px;
          color: $primary-color;
        }
        &:after {
          content: '\F105';
          position: absolute;
          right: 10px;
          top: 7px;
          font-size: 24px;
        }
        &:hover {
          &:before {
            color: white;
          }
        }

        span {
          display: block;
          font-size: 12px;
          &.date-selected {
            font-size: 14px;
            line-height: 1;
          }
        }
      }
      &.active {
        z-index: 99;
        button {
          z-index: 99;
          border: none;
          color: $primary-color;
          background: none;
          &:before {
            color: $primary-color !important;
          }
          &:after {
            display: none;
          }
        }
        .datepicker-wrapper {
          display: block;
        }
      }
      .datepicker-wrapper {
        @include ng-widget;
        display: none;
        .duration-options-wrapper {
          position: relative;
          .duration {
            @include ng-button;
            cursor: pointer;
            margin: 0;
            margin-bottom: 20px;
            text-align: center;
            &:after {
              font-family: 'icons';
              content: '\E808';
            }
          }
          &.active {
            .duration {
              display: block;
              opacity: 0;
            }
          }
          .duration-options {
            z-index: 999;
            @include ng-button;
            padding: 0;
            &:hover {
              background: white;
              color: #333;
            }
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;

            background: white;
            > div {
              padding: 10px 4px;
              &:not(.disabled) {
                &:hover {
                  background: #EEE;
                }
                cursor: pointer;
              }
              &.duration-selector {
                color: $grey-color;
                font-weight: normal;
                &:hover {
                  background: none;
                }
                &:after {
                  font-family: 'icons';
                  content: '\F105';
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .date-navigator {
          margin: 10px 0;
          font-weight: bold;
          color: $secondary-color;
          text-align: center;
          .current {
            display: inline-block;
            width: calc(100% - 30px);
          }
          .previous,
          .next {
            font-size: 0;
            width: 10px;
            display: inline-block;
            cursor: pointer;
            &:after {
              font-family: 'icons';
              content: '\F105';
              font-size: 17px;
            }
            &.hidden {
              display: none;
            }
          }
          .previous {
            &:after {
              content: '\F104';
            }
          }
        }
      }
      .special-period,
      .clear-date,
      .close {
        margin: 10px 4px;
        cursor: pointer;
        font-size: 12px;
      }
      .special-period {
        margin-bottom: 30px;
        position: relative;
        &:before {
          width: 25px;
          height: 3px;
          content: ' ';
          left: 0;
          background: $tertiary-color;
          display: inline-block;
          margin-right: 10px;
        }
      }

      .clear-date {
        color: $grey-color;
        &:hover {
          color: darken($grey-color, 10);
        }
      }
      .close {
        @include button-general();
        font-size: 14px;
        padding: 5px;
        line-height: 1;
        float: right;
        text-transform: uppercase;
        height: auto;
        margin-top: 0;
      }
    }
    .guest-widget {
      display: block;
      position: relative;
      &.active {
        z-index: 99;
        button {
          z-index: 99;
          border: none;
          color: $grey-color;
          background: none;
          &:hover {
            background: none;
            color: $grey-color;
          }
        }
      }
      > button {
        @include ng-button;
        &:before {
          content: '\E806';
        }
        &:after {
          content: '\F105';
        }
      }
      app-guest-widget {
        @include ng-widget;
        table {
          width: 100%;
          tr {
            td {
              padding: 5px 0;
              color: $secondary-color;
              font-size: 13px;
              font-weight: bold;
              &:nth-child(2) {
                text-align: right;
                select {
                  @include ng-button;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-align: center;
                  width: 40px;
                  padding: 5px;
                  &:after {
                    font-family: 'icons';
                    content: '\E808';
                  }
                  &::-ms-expand {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    app-option-picker {
      .navigation {
        @include clearfix;
        margin-bottom: 5px;
        font-family: $heading-font;
        font-size: 13px;
        font-weight: 300;
        .earlier {
          cursor: pointer;
          &:hover {
            color: $tertiary-color;
          }
          float: left;
          &:before {
            content: '\F104';
            margin-right: 5px;
            font-family: 'icons';
          }
        }
        .later {
          float: right;
          cursor: pointer;
          &:hover {
            color: $tertiary-color;
          }
          &:after {
            content: '\F105';
            margin-left: 5px;
            font-family: 'icons';
          }
        }
      }
      .offer-browser {
        padding: 10px 7px;
        position:relative;
        > ul {
          > li {

            &.selected,
            &:hover {
              cursor: pointer;
              .offer {
                .details, .price-info {
                  color: $secondary-color;
                }
                .details {
                  background: rgb(248, 248, 248);
                }
                .price-info {
                  background: rgb(253, 253, 253);
                }
              }
            }
            &.selected {
              z-index: 2;
              position: relative;
              border: none !important;
              transform: scale(1.05);
              box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px 1px;
              .offer {
                .details, .price-info {

                }
                .price-info {
                  background: lighten($primary-color, 55);
                }
              }
            }
            background: white;
            .offer {
              font-size: 13px;
              padding: 0;
              text-align: center;
              .details {
                border-top: 1px solid $option-border-color;
                border-bottom: 1px solid $option-border-color;
                border-left: 1px solid $option-border-color;
                width: 100%;
                background: rgb(252, 252, 252);
                width: 100%;
                height: 100px;
                display: table;
                .inner {
                  display: table-cell;
                  vertical-align: middle;
                  font-size: 12px;
                  .date {
                    font-weight: bold;
                  }

                  .duration {
                    width: 90%;
                    margin: 0 5%;
                    white-space: normal;
                    font-size: 12px;
                  }
                }

              }
              &.special .details .inner .duration {
                color: $tertiary-color;
              }
              .price-info {
                border-bottom: 1px solid $option-border-color;
                border-left: 1px solid $option-border-color;
                width: 100%;
                height: 100px;
                display: table;
                margin: 0;
                > .inner {
                  display: table-cell;
                  vertical-align: middle;
                  width: 100%;
                  position: relative;
                  .discounts {
                    @include teaser-discounts;
                    top: 0;
                    font-size: .7em;
                    .inner {
                      top: -30px;
                      left: -14px;
                    }
                  }
                  .price-original {
                    width: 100%;
                    font-size: 13px;
                    color: $grey-color;
                    text-decoration: line-through;
                  }
                  .price {
                    font-size: 18px;
                    @include breakpoint(0 $mobile) {
                      font-size: 16px;
                    }
                    .decimals {
                      font-size: .6em;
                      position: relative;
                      bottom: .4em;
                    }
                    width: 100%;
                    font-weight: bold;
                    color: $secondary-color;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    > .discounts {
      @include teaser-discounts;
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
      .inner {
        top: auto;
        bottom: -16px;
        left: auto;
        right: -184px;
      }
    }
    > .description {
      font-size: 14px;
      margin: 5px 0;
      font-weight: bold;
      > div {
        display: block;
        margin-right: 5px;
        > div {
          display: inline-block;
        }
      }
      .hide-for-small {
        display: none;
      }
      .label {
        width: 94px;
        display: inline-block;
      }
      em {
        display: none;
      }
    }
    > .price {
      font-size: 28px;
      color: $secondary-color;
      font-weight: bold;
      margin: 10px 0;

      &.price-on-request {
        font-size: 20px;
      }
      .original-price {
        font-size: 16px;
        display: inline-block;
        font-weight: normal;
        color: $grey-color;
        text-decoration: line-through;
      }
      .decimals {
        font-size: .6em;
        position: relative;
        bottom: .4em;
      }

    }
    > form > input[type="submit"] {
      background: $booking-button-color;
      color: white;
      font-size: 14px;
      padding: 10px 15px;
      border-radius: 2px;
      border: none;
      margin-bottom: 10px;
      font-weight: bold;
      &:hover {
        background-color: $booking-button-color-hover;
      }
    }

  }
  .supplement {
    margin-right: 100px;
    font-size: 12px;
    color: darken($grey-color, 15);
    span.label {
      font-weight: bold;
    }
  }
  .particulars{
    margin-top:1em;
  }
}

app-datepicker-widget {
  table {
    width: 100%;
    tr {
      td {
        width: calc(100% / 7);
      }
    }
  }
  .previous {
    &.hidden {
      display: none;
    }
  }
  .duration-options-wrapper {
    .duration-options {
      display: none;
    }
    .duration-selector {
      display: none;
    }
    &.active {
      .duration {
        display: none;
      }
      .duration-selector,
      .duration-options {
        display: block;
      }
    }
  }
}

app-option-picker {
  @mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
  }

  @mixin box-sizing($type...) {
    -moz-box-sizing: $type;
    -o-box-sizing: $type;
    -webkit-box-sizing: $type;
    transition: $type;
  }
  .navigation {
    .earlier,
    .later {
      &.disabled {
        opacity: .3;
      }
    }
  }
  .offer-browser {
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      white-space: nowrap;
      position: relative;
      @include transition(transform .2s ease);
      li {
        user-select: none;
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
        width: calc(100% / 3);
        @include box-sizing(border-box);
        &.selected {
          border: 1px solid green;
        }
      }
    }
  }
}

app-supplement span.supplements{
  &:after{
    display:none;
  }
  span{
    &:after{
      display:none;
    }
   span {
   white-space: nowrap;

  }
  }
}

ngb-datepicker {
  outline: none;
  font-size: 12px;
  border: none !important;
  width: 100%;
  .ngb-dp-header {
    display: none;
  }
  .ngb-dp-months {
    width: 100%;
    .ngb-dp-month-name {
      display: none;
    }
    .ngb-dp-month {
      ngb-datepicker-month-view {
        .ngb-dp-week {
          .ngb-dp-weekday {
            display: none;
          }
          .ngb-dp-day {
            width: calc(100% / 7);
            width: calc(100% / 7.001);
            display: inline-block;
            text-align: center;
            color: $grey-color;
            font-weight: bold;
            height: auto;
            &.weekday {
              text-align: center;
              color: $grey-color;
            }
            &.disabled span {
              border: none;
              &.special {
                &:after {
                  display: none;
                }
              }
              &:hover {
                background: none !important;
              }
            }
            > span {
              padding: 6px 0;
              margin: 3px;
              display: block;
              border-radius: 0;
              text-align: center;
              position: relative;
              color: black;
            }
            &:not(.disabled) > span {
              border: 2px solid $primary-color;

              &:hover:not(.disabled),
              &.selected {
                background: $primary-color;
                color: white;
              }
              &.special {

                &:after {
                  position: absolute;
                  width: 80%;
                  bottom: 3px;
                  height: 3px;
                  margin: 0 10%;
                  background: $tertiary-color;
                  content: ' ';
                  left: 0;
                }
              }
            }
          }
          .ngb-dp-day.disabled {
            opacity: .3;
          }
          .bg-dp-day.hidden {
            display: none;
          }
          .ngb-dp-day .selected {
            background: black;
            color: white;
          }
        }
      }
    }
  }
}
