.page-book {
  #cost-overview {
    @include clearfix;
    border-radius: 5px;
    h3 {
      margin-top: 0;
      margin-bottom: 5px;
    }
    h4 {
      margin: 5px 0 5px 0;
      clear: left;
      float: left;
      font-size: 16px;
    }
    .inclusive-wrapper {
      margin-bottom: 10px;
    }
    .inclusive label {
      padding-left: 5px;
      &:before {

        font-family: 'icons';
        content: '\E826';
        display: inline-block;
        background: green;
        font-size: 10px;
        color: white;
        border-radius: 50%;
        padding: 2px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
    .discounts {
      color: $secondary-color;
      font-weight: bold;
    }
    label {
      float: left;
      width: calc(100% - 100px);
      text-align: left;
      padding: 0 5px 10px 0;
      &.no-span {
        width: 100%;
      }
    }
    .total-cost label {
      font-size: 18px;
    }
    div {
      clear: left;
      float: left;
      width: 100%;
      text-align: left;
      line-height: 1;
      .currency {
        float: left;
        padding-right:10px;
      }
      &.subtotal,
      &.total-cost {
        font-weight: bold;
        font-size: 1.2em;
        padding-top: 10px;
        label {
          font-weight: bold;
        }
      }
      &.total-cost {
        margin-bottom: 20px;
      }
      &.subtotal {
        font-size: 1em;
        margin-bottom: 15px;
        padding-top: 10px;
        margin-top: 0;
        border-top: 1px solid darken($border_color, 15%);
      }
    }
  }

}

.page-book-thank-you {
  #block-system-main {
    @include breakpoint(0 $mobile) {
      padding: $gutter-width/2;
      h2 {
        padding: 0 !important;
      }

    }
  }
}