.page-node-ical{
  #block-system-main{
    table{
      box-shadow:rgba(black, .2) 0 0 .3rem;
      background:white;
      thead{
        border-bottom: 2px solid #eee;
      }
      th h3{
        font-weight:bold;
      }
      tbody {
        td{
          vertical-align:top;
        }
        .description{
          font-size:.9em;
          font-style:italic;
          color: #999;
        }
        label{
          font-weight:bold;
        }
      }
    }
  }
}
