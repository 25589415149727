
#secondary-menu {
  font-family: $heading-font;
  h2 {
    display: none;
  }
  ul {
    li {
      display:inline-block;
      a {
      margin:0 10px;
        background-position: right center;
        background-repeat: no-repeat;
        font-weight: 400;
        &:hover {
          color: darken($secondary-color, 2) !important;
          text-decoration: none;
        }
        &.language-link img {
          vertical-align: top;
          margin-top: 4px;
        }
      }
    }
  }
}

#menu-small {
  ul li {
    &.nl, &.de, &.en {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
      a {
        padding-left: 33px;
      }
    }
  }
}
