input.form-submit, a.button, div.button, input.button, button.ui-button {
  @include button-general;
}

#block-helper-newsletter-sign-up, #user-login, #helper-registration-form, #search-form, #helper-search-search-content-form, #helper-review-form, #helper-review-not-stayed-form, #user-pass {
  input.form-submit {
    height: 28px;
    @include button-general;
    z-index: 2;
    padding: 0 10px;
    //font-size: 12px;
    font-weight: normal;
    margin-right:1rem;
  }
}


/* Holiday homes search form */
#block-helper-search-search-holiday-homes {
  .not-front & {
    input.form-submit {
      @include button-general();
      margin-top: 10px;
    }
  }
}

.offers input.button.blue {
  @include button-general($booking-button-color);
}

.offers input.button.orange {
  @include button-general($preselected-booking-button-color);
}

.offers .offer input.button {
  border-radius: 2px;
  height: 30px;
  line-height: 30px;
  width: 100px;
  padding: 0;
}

a.arrow, div.arrow, .page-book input.form-submit, .instant-offer input.button, #helper-prize-question-form input.form-submit {
  @include button-general();
}