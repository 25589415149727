.node-type-collection,

.node-type-topic {
  @include breakpoint(0 $mobile) {
    h1.title {
      padding: $gutter-width;
    }
    .intro {
      padding: $gutter-width;
    }
  }
}

.node-type-page{
  .node.node-page{
    @include breakpoint(0 $mobile){
     h1.title{
       padding:$gutter-width;
     }
      .image-slide{
        float:none;
        margin-left:0;
      }
      .column{
        padding:$gutter-width;
      }
    }
  }
}

@include breakpoint(0 $mobile) {
  .node-type-city,
  .node-type-region,
  .node-type-offers-page,
  .node-type-overview-page{
    #block-system-main{
      > div:not(.block-holiday-homes),
      > article {
        padding: $gutter-width;
        @include breakpoint(0 $mobile) {
          padding: $gutter-width/2;
        }
      }
      > .block-holiday-homes > h2{
        margin-left:$gutter-width/2;
      }
    }
  }
}