@mixin characteristics-of-trust {
  @include clearfix;
  border-top:1px solid $border-color;
  border-bottom:1px solid $border-color;
  @include breakpoint(0 $tab){
    border-bottom:none;
  }
  padding: $gutter-width 0;
  margin: $gutter-width 0;
  @include icon-list;
  li {
    &.post-payment {
      @include icon(bank);
    }
    &.lowest-price-guarantee {
      @include icon(thumbs-up);
    }
    &.credit-card {
      @include icon(creditcard);
    }
    &.debit-card {
      @include icon(mastercard);
    }
    &.secure-payment {
      @include icon(lock);
    }
  }
}