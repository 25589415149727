@import "../../flean_base_2016/sass/variables/_colors.scss";

$primary-color: #6db8e8; // Done
$secondary-color: #878786; // Done
$tertiary-color:  $primary-color; // Done
$base-font-color: $secondary-color;
$heading-font-color: $secondary-color;
$link-color: $primary-color;
$border-color: #cfe4f7; // Done

$overlay-color: $border-color;
$grey-color: #AAA;
$icon-color: #AAA;
$gold: #dc9d00;
$booking-button-color:$tertiary-color;
$booking-button-color-hover: darken($booking-button-color, 20);

$search-home-bg-color: $border-color;
$grey-background-color: #EFEFEF;
$form-background-color: $search-home-bg-color;
$advantage-color: #598527;
$positive-color: green;
$negative-color: red;
$star-reviews-color: #898989;

$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;

$legenda-available-arrival-date-color: $secondary-color;
$legenda-selected-arrival-date-color: $tertiary-color;

@import "../../flean_base_2016/sass/variables/_sizes.scss";
@import "../../flean_base_2016/sass/variables/_base.scss";
@import "../../flean_base_2016/sass/variables/_typography.scss";

@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700');

body {
  h1, h2, h3 {
    font-weight: 700;
  }
}

@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_add_this.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_animations.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_buttons.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_characteristics_of_trust.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_clearfix.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_holiday_home_mixins.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_icon_list.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_icons.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_inline_block_lists.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_loader.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_luxury_level.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_price_info.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_stars.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/abstractions/_teaser_discounts.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/legacy/_legacy.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_buttons.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_colors.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_cookie_consent.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_forms.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_layout.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_loader.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_tabs.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/_typography.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/forms/_search_form.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/partials/layout/_footer.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/blocks/_block_helper_recently_watched.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/blocks/_block_helper_search.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/blocks/_block_holiday_homes.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/blocks/_theme_switcher.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_add_this.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_booking_widget.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_main_characteristics.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_map_embed.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_photo_viewer.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_review_slider.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_reviews.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/elements/_tooltip.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/navigation/_breadcrumb.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/navigation/_contextual_links.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/navigation/_main_menu.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/navigation/_menu_mobile.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/navigation/_secondary_menu.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/navigation/_subnavigation.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/node/_holiday_home_and_complex.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/node/_other_node_types.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/pages/_book_funnel.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/pages/_book_pages.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/pages/_faq.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/pages/_front.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/pages/_holiday_home.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/components/pages/_ical.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_easy-responsive-tabs.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_easydropdown.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_jquery.ui.datepicker.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_jquery.ui.theme.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_normalize.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_selectordie.scss";
@import "/home/askibinski/www/drupal7_flean/sites/all/themes/flean_base_2016/sass/vendor/_slick.scss";


#secondary-menu ul li a:hover {
  text-decoration: underline !important;
  color: #fff !important;
}

.discounts > .inner > div {
  background: rgba($tertiary-color, .9) !important;
}

.not-front #reviews {
  .ratings .averages .label, .average-rating {
    background: $secondary-color;
  }
}