.sidebars, .ui-selectmenu-menu, .sort, .region-content-top, .ui-dialog {

  .ui-widget-content {
    border: 1px solid #aaa;
    background: #fff url(/misc/ui/images/ui-bg_flat_75_ffffff_40x100.png) 50% 50% repeat-x;
    color: #222;
  }
  .ui-widget-content a {
    color: #222;
  }
  .ui-widget-header {
    border: 1px solid $border-color;
    background: lighten($border-color, 5%);
    color: $base-font-color;
    font-weight: bold;
  }
  .ui-widget-header a {
    color: #222;
  }
  .ui-widget :active {
    outline: none;
  }

  .ui-icon {
    width: 16px;
    height: 16px;
    background-image: url(/misc/ui/images/ui-icons_222222_256x240.png);
  }
  .ui-widget-content .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_222222_256x240.png);
  }
  .ui-widget-header .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_222222_256x240.png);
  }
  .ui-state-default .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_888888_256x240.png);
  }
  .ui-state-hover .ui-icon,.ui-state-focus .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_454545_256x240.png);
  }
  .ui-state-active .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_454545_256x240.png);
  }
  .ui-state-highlight .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_2e83ff_256x240.png);
  }
  .ui-state-error .ui-icon,.ui-state-error-text .ui-icon {
    background-image: url(/misc/ui/images/ui-icons_cd0a0a_256x240.png);
  }

  .ui-icon-triangle-1-s {
    background-position: -64px -16px;
  }

  .ui-corner-top {
    -moz-border-radius-topleft: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -moz-border-radius-topright: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px
  }
  .ui-corner-bottom {
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px
  }
  .ui-corner-right {
    -moz-border-radius-topright: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px
  }
  .ui-corner-left {
    -moz-border-radius-topleft: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px
  }
  .ui-corner-all {
    border-radius: 5px;
  }
}

.ui-widget-overlay {
  background: #aaa url(/misc/ui/images/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x;
  opacity: .3;
  filter: Alpha(Opacity=30)
}
.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: #aaa url(/misc/ui/images/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x;
  opacity: .3;
  filter: Alpha(Opacity=30);
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px
}
