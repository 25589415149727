

div.tabs {
  margin: 20px 5px 30px 0;
  border-bottom: 1px solid;
  width: 100%;
  @include clearfix;
}

/**
 * Tabs.
 */
ul.primary {
  clear: left;
  float: right; /* LTR */
  border-bottom: none;
  text-transform: uppercase;
  font-size: 11px;
  padding-top: 0;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
}
ul.primary li {
  float: left; /* LTR */
  list-style: none;
  margin: 0 1px;
}
@include breakpoint($large) {
  ul.primary {
    margin-right: 11px;
    font-size: 12px;
  }
  ul.primary li {
    margin: 0 2px;
  }
}

ul.primary li a:link,
ul.primary li a.active,
ul.primary li a:active,
ul.primary li a:visited,
ul.primary li a:hover,
ul.primary li.active a {
  display: block;
  float: left; /* LTR */
  line-height: 1;
  padding: 9px 8px;
  background: lighten($primary-color, 40%);
  color: $base-font-color;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-color: darken($border-color, 10%);
  border-bottom-color: $border-color;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  border-radius: 8px 8px 0 0;
  height: 28px;
}
ul.primary li a:hover,
ul.primary li.active a,
ul.primary li.active a.active,
ul.primary li.active a:active,
ul.primary li.active a:visited {
  background: lighten($primary-color, 50%);
  border-bottom-color: lighten($primary-color, 50%);
  color: $base-font-color;
  text-decoration: none;
}
ul.primary li a:hover {
  color: $base-font-color;
}
ul.primary li.active a:hover {
  color: $base-font-color;
}
.tabs-secondary {
  clear: both;
}
ul.secondary {
  float: right; /* LTR */
  font-size: 0.923em;
  padding: 0 3px 5px;
  line-height: 1.385em;
  overflow: hidden;
  background-color: #fff;
}
ul.secondary li {
  margin: 0 5px;
  float: none; /* LTR */
}
ul.secondary li a {
  background-color: #ddd;
  color: #000;
  display: inline-block;
}
ul.secondary li a,
ul.secondary li a:hover,
ul.secondary li.active a,
ul.secondary li.active a.active {
  padding: 2px 10px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
ul.secondary li a:hover,
ul.secondary li.active a,
ul.secondary li.active a.active {
  color: #fff;
  background: #666;
}
