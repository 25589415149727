.node-type-home-page {
  #block-helper-reviews-homepage {
    margin-bottom: $gutter-width*2;
  }


  #content-top {
    position: relative;
    #slideshow.sale{
      .usp-inner{
        max-width:800px;
        display:flex;
        justify-content: center;
        align-items: center ;
        padding:0;
      }
      .usp{
        float:none;
        text-align:center;
        width:100%;
        h3{
          margin-bottom:1rem;
          font-size:2.5em;
        }
      }
      .usp-wrapper{
        height:100%;
        display:flex;
      }
      .slide-button-wrapper{

        a.slide-button{
          background:$secondary-color;
          text-shadow: none;
          color:white;
          font-weight:bold;
          font-size:1.3rem;
          padding:1rem 2rem;
          line-height: 1;
          box-shadow:rgba(black,.4) 0 0 5px;
          border-radius:2px;
          display:inline-block;
          &:hover{
            background:darken($secondary-color, 5);
            text-decoration: none;
          }
        }
      }
    }
    .usp-wrapper {
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      .usp-inner {
        padding: $gutter-width *3;
       // max-width: $max_width;
        margin: 0 auto;
        .usp {
          color: white !important;
          text-shadow: 0px 1px 1px #212121;
          width: 310px;
          float: right;
          h3 {
            color: white;
          }
        }
      }
    }
    &.normal {
      max-width: $max_width - $gutter-width*2;
      margin: 0 auto;
      float:none;
      #block-helper-search-search-holiday-homes {
        position: absolute;
        top: $gutter-width;
        left: $gutter-width;
        z-index: 99;
        width: 470px;
        padding: 20px 0 20px 20px;
        margin: 0;
        @include breakpoint(max-width $large) {
          padding: 10px 0 10px 10px;
          width: 430px;
          .front & .form-item {
            width: 200px;
          }

        }
        @include breakpoint(max-width $medium) {
          position: relative;
          float: left;
          top: 0;
          left: 0;
          background-image: none;
          width: 100%;
          padding: $gutter-width;
          input.form-submit {
            font-size: 16px !important;
          }
          .form-item, input.form-submit {
            width: 100% !important;
            margin: 5px 0 !important;
          }
          h2 {
            margin: 0 0 10px 0 !important;
          }
        }
      }

    }

    #slideshow {
      // This is a little trick to apply this code only to IE 10 and 11.
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-height:350px;
        overflow: hidden;
      }

      &.single-image {
        img {
          max-width: 100%;
        }
      }
      @include breakpoint(max-width $medium) {
        display: none;
      }
      float: left;
      width: 100%;
      margin: 0px 0 40px 0;
      &.normal {

        img {
          vertical-align: bottom;
          max-width: none;
          height: 320px;
          float: left;
        }
      }
      &.wide {

        img {
          height: auto;
          width: 100%;
        }
      }

      .slick-slide {
        position: relative;
        width: 100%;
      }

      .slick-dots {
        li {
          button {
            &:hover, &:focus {
              &:before {
                //opacity: $slick-opacity-on-hover;
              }
            }
            &:before {
              color: $primary-color;
              //opacity: $slick-opacity-not-active;
            }
          }
          &.slick-active button:before {
            color: $tertiary-color;
            //opacity: $slick-opacity-default;
          }
        }
      }
    }
  }
  @include breakpoint($mobile) {
    #content-top.wide {
      .search-wrapper {
        position: absolute;
        bottom: 100px;
        width: 100%;
      }
      .container {
        position: relative;
        bottom: 0;
        #block-helper-search-search-holiday-homes {
          background: none;
          padding: 0 $gutter-width;
          border-radius: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          h2 {
            display: none;
          }
          input.form-submit {
            width: 24%;
            margin: 0;
          }
          .form-item {
            width: 19%;
            margin: 0;
            .sod_select {
              line-height: 1.6;
            }
            .sod_label {
              border-radius: 0;
            }
            .form-text {
              height: 40px;
            }

          }
        }
      }
    }
  }
  @include breakpoint($medium) {
    #content-top {
      float: left;
      width: 100%;
    }
    .search-wrapper {
      .container {
        position: relative;
      }
    }
  }
}

