.node .subnavigation {
  @include breakpoint(0 $tab) {
    .subnavigation-wrapper {
      display: none !important;
    }
    &.active-group-present {
      .subnavigation-wrapper {
        display: block !important;
        @include breakpoint (0 $mobile){
          display: none !important;
        }
      }
    }
  }
  .subnavigation-wrapper {
    position: fixed;
    opacity: 0;
    z-index: -99;
    transition: opacity 0.1s ease;
    .subnavigation & {
      opacity: 1;
      display: block;
      z-index: 99;
    }

    width: 100%;
    background: $primary-color;
    height: $subnavigation-height;
    left: 0;
    top: 0;
    .adminimal-menu & {
      margin-top: 29px;
    }
    .item-list {
      font-family: $heading-font;
      font-weight: $bold-weight;
      text-align: left;
      max-width: $max_width;
      margin: 0 auto;
      display: block;
      float: none;
      > ul {
        @include clearfix;
        > li {
          @include breakpoint($mobile) {
            .booking {
              display: none;
            }
          }
          a {
            float: left;
            color: white;
            padding: 20px 20px 20px 20px;
            font-size: 14px;
            color: lighten($primary-color, 40);
            &:hover {
              text-decoration: none;
            }
            &.active-group {
              color: white;
            }
            @include breakpoint(0 $max_width) {
              padding: 0;
              visibility: hidden;
              width: 0;
              &.active-group {
                padding: 20px $gutter-width;
                width: auto;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

}