
/* PREFIXED CSS */
.easydropdown,
.easydropdown div,
.easydropdown li,
.easydropdown div::after{
	-webkit-transition: all 150ms ease-in-out;
	-moz-transition: all 150ms ease-in-out;
	-ms-transition: all 150ms ease-in-out;
	transition: all 150ms ease-in-out;
}

.easydropdown .selected::after,
.easydropdown.scrollable div::after{
	-webkit-pointer-events: none;
	-moz-pointer-events: none;
	-ms-pointer-events: none;
	pointer-events: none;
}

/* WRAPPER */

.easydropdown{
	position: relative;
	width: 100%;
	cursor: pointer;
	border-radius: 3px;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.easydropdown.open{
	z-index: 2;
}

.easydropdown:hover{
	box-shadow: 0 0 5px rgba(0,0,0,.15);
}

.easydropdown.focus{
	box-shadow: 0 0 5px rgba(51,102,248,.4);
}

/* CARAT */
.easydropdown .carat{
	position: absolute;
	right: 8px;
	top: 50%;
	margin-top: -2px;
	border: 6px solid transparent;
}

.easydropdown.open .carat{
	margin-top: -10px;
	border-top: 6px solid transparent;
}


/* OLD SELECT (HIDDEN) */
.easydropdown .old{
	position: absolute;
	left: 0;
	top: 0;
	height: 0;
	width: 0;
	overflow: hidden;
}

.easydropdown select{
	position: absolute;
	left: 0px;
	top: 0px;
}

.easydropdown.touch .old{
	width: 100%;
	height: 100%;
}

.easydropdown.touch select{
	width: 100%;
	height: 100%;
	opacity: 0;
}

/* SELECTED FEEDBACK ITEM */ 
.easydropdown .selected,
.easydropdown li{
	display: block;
	line-height: 1;
	padding: 8px 12px;
	overflow: hidden;
}

.easydropdown .selected::after{
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 60px;
	border-radius: 0 2px 2px 0;
}

/* DROP DOWN WRAPPER */

.easydropdown div{
	position: absolute;
	height: 0;
	left: -1px;
	right: -1px;
	top: 100%;
	margin-top: -1px;
	background: #fff;
	border-radius: 0 0 3px 3px;
	overflow: hidden;
	opacity: 0;
}

/* Height is adjusted by JS on open */
.easydropdown.open div{
	opacity: 1;
	z-index: 2;
}

/* FADE OVERLAY FOR SCROLLING LISTS */

.easydropdown.scrollable div::after{
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 50px;	
	box-shadow: inset 0 -50px 30px -35px #fff;
}

.easydropdown.scrollable.bottom div::after{
	opacity: 0;
}

/* DROP DOWN LIST */
.easydropdown ul {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	list-style: none;
	overflow: hidden;
}

.easydropdown.scrollable.open ul{
	overflow-y: auto;
}

/* DROP DOWN LIST ITEMS */
.easydropdown li{
	list-style: none;
	padding: 8px 12px;
}

/* .focus class is also added on hover */
.easydropdown li.focus {
	position: relative;
	z-index: 3;
}

.easydropdown li.active{
	font-weight: 700;
}