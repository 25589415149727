#helper-search-block-content-form {
  position: relative;
  .form-type-textfield {
    width: calc(100% - 40px);
    margin-right: 4px;
    display: inline-block;
    margin-bottom: 0;
    input[type="text"] {
      font-size: 13px;
      -webkit-appearance: none;
      -moz-appearance: none;
      line-height: 34px;
      padding: 0 10px;
      height: auto;

      &:focus {
        outline: none;
        border-color: $grey-color;
      }
      @include breakpoint(0 $mobile) {
      //  background: white;
     //   border: none;
      }
      ::-webkit-input-placeholder { /* Chrome */
        color: $grey-color;
        font-size: 12px;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $grey-color;
        font-size: 12px;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $grey-color;
        font-size: 12px;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 4 - 18 */
        color: $grey-color;
        opacity: 1;
        font-size: 12px;
      }
    }
  }
  .submit-button {
    white-space: nowrap;
    width: 36px;
    position: absolute;
    top: 0;
    border-radius: 2px;
    right: 0;
    height: 36px;
    @include breakpoint(0 $mobile) {
      &:before {
      }
    }
    background: $tertiary-color;
    display: inline-block;
    &:hover {
      background-color: darken($tertiary-color, 10);
    }
    input[type="submit"] {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0;
      width: 100%;
      padding: 0;
      height: 100%;
      background: none;

    }
    &:before {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: white;
      font-size: 16px;
      padding: 6px 11px;
    }
    @include icon('search');
  }
}
.sod_label.preselected{
  font-weight:bold;
}

#helper-search-holiday-homes-form .form-type-textfield {
  padding: 0;
  input {
    padding: 0 10px;
  }
}