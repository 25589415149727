#faq {
  margin-top: 20px;
  width: 100%;
  @include breakpoint(0 $mobile){
    margin-top:0;
  }
}

.node-type-faq-page #main #block-system-main {

  .category {
    margin-top: 10px;
    padding-top: 10px;
  }
  #categories .first {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  #entries .wrapper {
    html.js & fieldset.collapsed {
      height: inherit;
    }
    legend {
      border-bottom: none;
      width: 75%;
      line-height: 40px;
      font-weight: bold;
      a {
        line-height: 40px;
        font-weight: bold;
        @include icon(minus-circled, 'before');
        &:hover {
          text-decoration: none;
        }
        &:before {
          margin-right: 5px;
          font-size: 16px;
          color: $secondary-color;
        }
      }
    }
    fieldset.collapsed legend {
      margin-bottom: 5px;
      font-weight: normal;
      a {
        font-weight: normal;
        @include icon(plus-circled, 'before');
        &:before {
          color: $tertiary-color;
        }
      }
    }
  }
}


/* Contact page */

#categories {
  float: left;
  width: 25%;
  padding-right: 30px;
  @include breakpoint (0 $mobile){
    width:100%;
    float:none;
    padding-right:0;
    padding:$gutter-width;
  }
}

#entries {
  width: 75%;
  float: left;
  @include breakpoint (0 $mobile){
    width:100%;
    float:none;
    padding-right:0;
    padding:$gutter-width;
    legend{
      width:100% !important;
    }
  }
}
