/* Box sizing: border-box */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  &.adminimal-menu {
    @include breakpoint($medium){
    margin-top: 29px;
    }
  }
}

html{
  @include breakpoint(0 $mobile){
    overflow-x:hidden;
  }
}

#content {
  > h1 {
    @include breakpoint(0 $mobile) {
      padding: $gutter-width/2;
    }
  }
}

.social-footer {
  transform: scale(1.3);
  transform-origin: 0 0;
  margin-top: 20px;
  @include add-this;
  @include breakpoint ($tab) {
    display: none;
  }
  padding: $gutter-width $gutter-width/2;
}

.container {
  width: 100%;
  max-width: $max_width;
  margin: 0 auto;
  padding: 0 $gutter-width;
  @include breakpoint(max-width $medium) {
    padding: 0;
    .price-info {
      float:left;
      clear:left;
    }
  }
}

#header-top {
  width: 100%;
  @include clearfix;
  .container {
    @include clearfix;
  }
  #secondary-menu-wrapper {
    float: right;
    max-width: calc(100% - 200px);
    @include breakpoint(0 $mobile) {
      width: 100%;
      float: left;
      margin-left: -10px;
    }
  }

  padding: 20px 0;
  background: $primary-color;
  @include breakpoint(max-width $mobile) {
    display: none;
  }

  .questions {
   margin-bottom: 0px;
    float:left;
    @include icon(phone);
    &:before {
      margin-right: 5px;
    }
    @include breakpoint(max-width $mobile) {
      display: none;
    }
  }

}

.logo-search-and-menu {
  margin: 20px 0;
  @include breakpoint(0 $mobile) {
    margin: 0;
  }
  @include clearfix;
  #logo {
    float: left;
    @include breakpoint(0 $mobile) {

      display: none;
      float: none;
      padding: $gutter-width;
    }
    img {
      max-width: $max_logo_width;
      height: auto;
    }
  }
  .search-and-menu {
    .mobile-menu-wrapper {
      @include breakpoint($mobile) {
        @include breakpoint($mobile) {
          float: left;
        }
      }
    }
    .menu-trigger {
      @include breakpoint($mobile) {
        display: none;
      }
      float: right;
      padding: 5px;
      font-size: 18px;
      font-family: $heading-font;
      font-weight: $bold-weight;
      @include icon(menu);
      line-height: 1.2;
      color: white !important;
      vertical-align: middle;
      cursor: pointer;
      &:before {
        vertical-align: bottom;
        line-height: 1;
        margin-right: 5px;
        font-size: 22px;
        text-align: center;
        width: 20px;
        display: inline-block;
      }
      .toggle-menu & {
        @include icon(cancel);
      }
    }
    .search-trigger,
    .logo-mobile {
      display: none;
      @include breakpoint(0 $mobile) {
        display: block;
        &.with-logo {
          width:60px;
          height:30px;
          position:relative;
          a {
            width:100%;
            height:100%;
            position:absolute;
            background-position:0 50%;
            background-repeat:no-repeat;
            background-size: contain;
            font-size: 0;
          }
        }
      }
    }

    @include clearfix;
    @include breakpoint($mobile) {
      float: right;
    }
    @include breakpoint(0 $mobile) {
      width: 100%;
      display: block;
      clear: both;
      background: $primary-color;
      .logo-mobile {
        a {
          color: white;
          &:hover {
            text-decoration: none;
          }
        }
        font-family: $heading-font;
        font-size: 21px;
        float: left;
      }
      .menu-small {
        @include clearfix;
        label {

          float: right;
        }
      }
      .mobile-menu-wrapper {
        @include clearfix;
        padding: 15px $gutter-width/2;
        .toggle-menu & {
          padding-bottom: 0;
          border-bottom: 1px solid $menu-mobile-item-border-color;
        }

      }
      .search-trigger {
        &:before {
          font-family: 'icons';
          content: "\E82D";
          padding: 5px;
          display: block;
          font-size: 16px;
          color: white;

        }
        cursor: pointer;
        margin-left: 5px;
        font-weight: normal;
        float: right;
        display: block;
      }
      .helper-search-autocomplete {
        z-index: 999;
        display: none;
        .toggle-search & {
          display: block;
        }
      }
    }
    #main-menu,
    .helper-search-autocomplete {
      float: left;
      @include breakpoint (0 $mobile) {
        float: right;
      }
    }
    .helper-search-autocomplete {
      width: 200px;
      top: 7px;
      @include breakpoint(0 $mobile) {
        top: 0;
        width: 100%;
        padding: $gutter-width;
        background: white;
        .form-item {
          input {
            width: calc(100% - 10px);
          }
        }
        .container-inline {
          position: relative;
        }
      }
    }
    #main-menu {
      margin-right: 10px;
      margin-top: 16px;
    }
  }
}

.breadcrumb-wrapper {
  margin-bottom: 20px;
  @include breakpoint(0 $mobile) {
    display: none;
  }
}

#secondary-menu ul {
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
  @include breakpoint($large) {
    padding-top: 0;
    margin-left: 0;
    margin-right: -5px;
  }
}

div.messages {
  clear: left;
  float: left;
  width: 100%;
  margin: 20px 0 0 0;
}

.adminimal-menu #content-top #block-helper-search-search-holiday-homes {
  top: 131px;
  @include breakpoint(max-width $medium) {
    top: 0;
  }
}

/* Last minutes block on frontpage and holiday homes on complex page */
#block-helper-last-minutes, .node-type-complex .block-holiday-homes, .node-type-region .block-holiday-homes, .node-type-city .block-holiday-homes, .node-type-collection .block-holiday-homes, .node-type-topic .block-holiday-homes {
  h2, .all {
    float: left;
    //margin: 10px 0 30px 0;
    line-height: 40px;
  }
  h2#holiday-homes {
    line-height: 40px;

  }
  .all {
    padding-left: 20px;
    margin-left: 20px;

    //line-height: 40px;
    a {
      line-height: 20px;
      @include icon(angle-right, 'after');
      &:after {
        margin-left: 5px;
      }
    }
    a.arrow {
      line-height: 40px;
      &:after {
        content: "";
        margin-left: 0;
      }
    }
    @include breakpoint(max-width $medium) {
      padding-left: 0 !important;
      margin-left: $gutter-width !important;
      border-left: none !important;
      @include breakpoint(0 $mobile){
        margin-left:$gutter-width/2 !important;
      }
      //margin-bottom: 20px!important;
    }
  }
  .holiday-homes {
    clear: left;
    @include clearfix;
  }
}

#content .block-discounts {
  margin-top: 0;
  padding-top: 0;

  @include breakpoint(max-width $medium) {
    padding-top: 10px;
  }

  .recron {
    position: absolute;
    right: $gutter-width;
    top: 0;
    @include breakpoint(max-width $medium) {
      display: none;
    }
  }
}

#block-helper-newsletter-sign-up {
  padding-bottom: 20px;
  margin-bottom: 10px;
  text-align: center;

  padding-left:20px;
  padding-right:20px;
  .header {
    margin-right: 20px;
  }
  .container-inline {
    margin-left: 20px;
  }
  .header, .container-inline {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    @include breakpoint(max-width $medium) {
      display: block;
      float: left;
      clear: left;
      margin-left: 0;
      margin-right: 0;
    }
  }
  input[type="email"]{
    height:40px;
  }
  label {
    display: none;
  }
  .info {
    float: left;
    margin-top: 5px;
  }
  .form-submit {
    margin-left: 5px;
  }
}

#helper-search-autocomplete, #block-helper-newsletter-sign-up {
  .container-inline div, .container-inline label {
    float: left;
  }
  .form-submit {
    margin-left: 5px;
  }
}

#main {
  #content {
    float: left;
    //margin: 0 $gutter-width;
    width: 100%;
    position: relative;
    > .block {
      border-top: 1px solid $border-color;
      margin-top: $gutter-width;
      padding-top: $gutter-width;
      &.block-helper > h2,
      &.block-helper  > div > h2{
        @include breakpoint(0 $mobile){
          padding:0 $gutter-width/2;
        }
      }
      > .node-rent-page,
      > .node-resorts-page,
      >.node-page{
        @include breakpoint(0 $mobile){
          padding:0 $gutter-width/2;
        }
      }
      > h2{
        @include breakpoint(0 $mobile){

          padding:0 $gutter-width/2;
        }
      }
      &:nth-child(2) {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

.sidebar-first #main {
  position: relative;
  #sidebar-first {
    float: left;
    width: 25%;
    @include breakpoint(max-width $large) {
      width: 100%;
      margin-bottom: 20px;
    }
    @include breakpoint(max-width $large) {
      .block-helper-search.facet {
        padding: 0 $gutter-width;
      }
    }
    // Reset .item-list div for sidebars.
    .block .item-list {
      margin-top: auto;
      text-align: left;
      float: none;
    }
  }
  #content {
    padding-left: 20px;
    float: left;
    width: 75%;
    @include breakpoint(max-width $large) {
      float: left;
      padding-left: 0;
      margin-left: 0;
      width: 100%;
      a.button {
        font-size: 12px;
      }
    }
  }
}

#search-filters-toggle-label, #search-filters-toggle {
  display: none;
}

@include breakpoint(max-width $large) {
  #sidebar-first {
    display: none;
  }

  #search-filters-toggle-label {
    display: inline-block;
    padding: 5px 10px;
    line-height: 1;
    cursor: pointer;
    font-size: 20px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    &:after {
      vertical-align: middle;
      position: absolute;
      right: 0px;
    }
  }

  // Toggled State filters
  #search-filters-toggle:checked ~ #sidebar-first {
    display: block;
  }
  #search-filters-toggle ~ #search-filters-toggle-label {
    @include icon('angle-up', 'after')
  }
  #search-filters-toggle:checked ~ #search-filters-toggle-label {
    @include icon('angle-down', 'after')
  }
}

@include breakpoint(max-width $medium) {
  #search-filters-toggle-label {
    //   margin-top: 10px;
    border-radius: 0;
    padding: $gutter-width;
    margin-bottom:0;
    &:after{
      right:$gutter-width;
    }
  }
}

.sidebar-second {
  .content-sidebar-wrapper {
    position: relative;
    width: 100%;
    float: left;
    #content {
      float: left;
      width: 100%;
      padding-right: 248px;
      min-height: 580px;
      .author, .terms {
        float: left;
        margin-bottom: 10px;
      }
      .author {
        margin-right: 5px;
      }
      .terms {
        a {
          margin-left: 5px;
        }
      }
      @include breakpoint(max-width $medium) {
        padding-right: 0;
        min-height: 0;
        .field-body {
          margin-left: $gutter-width;
          margin-right: $gutter-width;
          @include breakpoint(0 $mobile){
            margin-left:$gutter-width/2;
            margin-right:$gutter-width/2;
          }
        }
        .author {
          margin-left: $gutter-width;
          @include breakpoint(0 $mobile){
            margin-left:$gutter-width/2;
          }
          margin-right: 0;
        }
        .terms {
          margin-left: $gutter-width;
          @include breakpoint(0 $mobile){
            margin-left:$gutter-width/2;
          }
        }
      }
    }
    #sidebar-second {
      position: absolute;
      top: 0;
      right: 0;
      width: 228px;
      @include breakpoint(max-width $medium) {
        position: relative;
        left: 0;
        float: left;
        width: 100%;
      }
      .block {
        margin-bottom: 20px;
      }
      #block-helper-facebook-like-box {
        margin-left: -8px;
      }
    }
  }
}

.view-blog {
  .view-header, .views-row {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .views-row {
    #block-views-blog-extra-blog-items &.views-row-last {
      border-bottom: none !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    a.image {
      clear: left;
      float: left;
      width: 30%;
      padding: 3px;
    }
    .content {
      float: left;
      width: 70%;
      padding-left: 20px;
    }
    @include breakpoint(max-width $large) {
      a.image, .content {
        width: 100%;
      }
      a.image {
        padding: 0;
        border: none;
        margin-bottom: 10px;
      }
      .content {
        padding-left: 0;
      }
    }
  }
  @include breakpoint(max-width $medium) {
    h2, .read-more {
      margin-left: $gutter-width;
      margin-right: $gutter-width;
      @include breakpoint(0 $mobile){
        margin-left:$gutter-width/2;
        margin-right:$gutter-width/2;
      }
    }
  }
}

@include breakpoint(max-width $medium) {
  .node-type-article .content-sidebar-wrapper #content .image img {
    padding: 0;
    border: none;
    margin-bottom: 10px;
  }
  #sidebar-second {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
    h2 {
      padding-left: 0;
      padding-right: 0;
    }
    .node-type-page & {
      margin-top: 20px;
    }
  }
}

.page-book-step-1, .page-book-step-2, .page-book-step-3 {
  h1 {
    //display: none;
    margin: 20px 0 0 0;
  }
  .menu-small {
    display: none;
  }
}

.page-book #main {
  #content {
    float: left;
    width: 100%;
  }
  /* Form */
  form h3 {
    width: 100%;
  }
  .form-item {
    width: 100%;
    float: left;
    line-height: 32px;
    label {
      width: 60%;
      float: left;
      line-height: 32px;
      @include breakpoint(max-width $medium) {
        //width: 70%;
      }
    }
    select, span {
      //width: 10%;
      float: left;
      line-height: 32px;
    }
    span {
      width: 20%;
      @include breakpoint(max-width $medium) {
        //width: 20%;
      }
    }
  }
  .form-item-adults, .form-item-children-big, .form-item-children-small, .form-item-babies {
    label {
      width: 80%;
      @include breakpoint(max-width $medium) {
        //width: 80%;
      }
    }
  }
  .number-of-guests, .contact-information {
    width: 100%;
    div {
      width: 100%;
      label {
        float: left;
        clear: left;
        padding-right: 10px;
      }
    }
  }
  .address div {
    float: left;
    width: 75%;
  }
  .mobile {
    clear: left;
  }
  .contact-information label {
    width: 55px;
  }
  #helper-book-step-3-form {
    @include breakpoint(max-width $medium) {
      padding-left: 0 !important;
      padding-right: 0 !important;

    }
    clear: left;
    h3 {
      //padding-left: 0 !important;
    }
  }
}


.page-book-thank-you {
  h1 {
    margin-top: 20px;
  }
  table {
    clear: both;
    div {
      //width: 90%;
    }
  }
  h2 {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  strong {
    font-size: 16px;
    padding-top: 10px;
    float: left;
  }
  td.label {
    padding-right: 5px;
  }
  td {
    padding-top: 5px;
  }
  @include breakpoint(max-width $medium) {
    tr, td {
      width: 100%;
      float: left;
      clear: left;
    }
    td {
      &.label {
        font-weight: bold;

      }
      &.value {
        padding-bottom: 10px;
        padding-top: 0;
      }
      strong {
        padding-top: 10px;
        padding-bottom: 0;
      }
    }
  }
}

#block-helper-book-general-request {
  .form-type-textfield, .form-type-textarea, .form-type-select {
    label {
      width: 30%;
    }
  }
  .form-item-newsletter .form-checkbox, input.form-submit, .captcha img {
    margin-left: 30%;
  }
}

#block-helper-prize-question {
  .form-type-textfield, .form-type-textarea, .form-type-select {
    label {
      width: 20%;
    }
  }
  .form-item-newsletter .form-checkbox, input.form-submit, .captcha img {
    margin-left: 20%;
  }
}

#block-helper-book-general-request, #block-helper-prize-question {
  .form-item, fieldset, div.submit {
    clear: left;
    float: left;
    width: 60%;
    @include breakpoint(max-width $large) {
      width: 100%;
    }
    margin: 5px 0;
  }

  .form-item-captcha-response {
    clear: left;
    float: left;
    width: 100%;
  }

  .form-type-select {
    margin-bottom: 10px;
  }
  .form-type-textfield, .form-type-textarea, .form-type-select {
    label {
      float: left;
      padding-right: 10px;
      @include breakpoint(max-width $large) {
        width: 100%;
        clear: left;
      }
    }
    input, textarea {
      float: left;
      width: 70%;
      @include breakpoint(max-width $large) {
        width: 100%;
      }
    }
    select {
      float: left;
      width: 40%;
    }
  }
  fieldset {
    .form-type-textfield {
      clear: none;
      width: inherit;
      input.form-text {
        width: inherit;
      }
    }
  }
  .form-type-checkboxes .form-checkboxes, .fieldset-wrapper {
    margin-top: -20px;
    padding-left: 30%;
    @include breakpoint(max-width $large) {
      clear: left;
      margin-top: 0;
      padding-left: 0;
    }
  }
  .form-item-newsletter .form-checkbox, input.form-submit, .captcha img {
    @include breakpoint(max-width $large) {
      margin-left: 0;
    }
  }

  div.captcha {
    clear: left;
    float: left;
    width: 60%;
    margin: 5px 0;
    @include breakpoint(max-width $large) {
      width: 100%;
    }
    .description {
      float: left;
    }
  }

  input.form-submit, .captcha img {
    margin-top: 5px;
    clear: left;
    float: left;
  }
}

// Hide for small!!
@include breakpoint(max-width $medium) {
  #header {
    #main-menu-wrapper #main-menu,
    #secondary-menu-wrapper {
      display: none;
    }
  }
  .tabs {
    display: none;
  }
  #main {
    .slider-thumbs {
      display: block;
      margin: $gutter-width !important;
    }
  }
  .hide-for-small, .characteristics-of-trust, .sort .view,
  #reliability,
  .pager-item, .pager-ellipsis,
  #block-helper-reviews-homepage {
    display: none;
  }
  .node-type-rent-page {
    .field-rent-review, #rent-reviews, .field-rental-plans, .field-body, .field-advantages, .field-rent-faq-title, .field-rent-faq {
      display: none;
    }
    .contact {
      display: block;
      h2 {
        display: none;
      }
    }
  }
}



#content, #content-bottom {
  .block {
    width: 100%;
    float: left;
    clear: left;
    margin-top: 20px;
    padding-top: 20px;
  }
  #block-system-main, #block-helper-destinations {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
  .node-type-complex & .block-holiday-homes {
    @include breakpoint(max-width $large) {
      margin-top: 20px;
    }
  }
  #block-helper-search-holiday-homes {
    margin-top: 0;
    padding-top: 0;
  }
  .destinations-blocks {
    border-top: none;
    padding-bottom: 0;
    margin-bottom: 20px;
    @include breakpoint(max-width $medium) {
      padding-top: 0;
    }
  }
  .block-discounts {
    border-top: 0;
  }

  .page-blocks::before {
    content: " ";
    margin: 10px $gutter-width;
    clear: left;
    display: block;
    @include breakpoint(max-width $medium) {
      margin: 0 0 10px 0;
    }
  }
}

#block-helper-recently-watched-3 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  clear: left;
  @include breakpoint(0 $mobile) {
    h2 {
      padding: $gutter-width;
    }
  }
}

.image-slide, .node-type-page #area .map.right {
  margin-bottom: 20px;
  float: right;
  width: 50%;
  margin-left: $gutter-width;
}

.image-slide .slick-slider {
  margin-bottom: 0;
}

.node-type-page #area .map.wide {
  width: 100%;
  margin-top: 10px;
}

@include breakpoint(max-width $large) {
  #block-system-main h1 {
    width: 100%;
  }

  #availability {
    margin-top: 20px;
  }
}

#availability .info {
  margin-top: 10px;
}

.image {
  margin-bottom: 10px;
}

@include breakpoint(max-width $medium) {
  .image {
    margin-bottom: 0;
  }
  .image-slide, .node-type-page div.column .content {
    width: 100%;
    position: relative;
    z-index: 10;
  }
  .node-type-page #area .map.right {
    clear: left;
    width: 100%;
    float: left;
  }
  .node-type-page .column .image img {
    border: none;
    padding: 0;
  }
  .review-average-favorites {
    width: 100%;
    position: relative;
    z-index: 20;
    margin-top: -56px;
    padding-left: 20px;
    padding-top: 8px;
  }
  .arrow {
    //font-size: 12px !important;
  }
  .arrow {
    clear: both;
    float: left;
    margin-bottom: 20px;
  }
}

// Read more description only visible on small.
@include breakpoint($medium) {
  .show-for-small-only {
    display: none;
  }
}

.node-type-holiday-home, .node-type-holiday-home-xl {
  .price-info {
    .periode, .price, .availability, .instant {
      clear: left;
      float: left;
      margin-bottom: 20px;
    }
    .contact {
      margin: 10px 0 20px 15px;
      clear: left;
      float: left;
      a.phone {
        //margin-left: 20px;
      }
    }
  }
  .urls {
    clear: left;
  }

  .price-info .contact, .urls {
    a {
      display: inline-block;
      width: 180px;
    }
  }

}

.node-type-topic, .node-type-collection {
  @include breakpoint(max-width $medium) {
    .container .node .button.arrow {
      margin-left: 0;
    }
    .block-holiday-homes h2, .additional {
      padding-left: $gutter-width;
      padding-right: $gutter-width;
    }
  }
}

/* Responsive tabs */
.responsive-tabs {
  clear: both;
}

.resp-tabs-container {
  @include breakpoint(max-width $medium) {
    //margin: 0 -$gutter-width;
  }
}

.characteristics-of-trust {
  float: left;
  width: 22%;
  @include breakpoint(max-width $large) {
    width: 30%;
  }
}

h2.holiday-home-xl {
  clear: left;
  padding: 5px;
}


/*
.row {
  h3, ul {
    float: left;
    width: 25%;
    margin-bottom: 20px;
    padding-right: 20px;
  }
  h3 {
    font-size: 18px;
    clear: left;
  }
  @include breakpoint(max-width $medium)  {
    width: 100%;
    float: left;
    margin-bottom: 15px;
    h3, ul {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

.ratings {
  .averages, .overall {
    float: left;
    width: 50%;
    .average-rating {
      margin-left: 42%;
    }
  }
  @include breakpoint(max-width $large)  {
    .averages {
      width: 70%;
    }
    .overall {
      width: 30%;
      .average-rating {
        margin-left: 30%;
      }
    }
  }
  @include breakpoint(max-width $medium)  {
    .averages, .overall {
      clear: left;
      width: 100%;
    }
    .overall .average-rating {
      //margin-left: 50%;
    }
  }
}
.review {
  width: 100%;
  .content {
    float: left;
    width: 90%;
    padding-right: 30px;
    @include breakpoint(max-width $medium)  {
      width: 100%;
      padding-right: 0;
      h4 {
        line-height: 1.5 !important;
      }
    }
  }
  .average-rating {
    float: left;
    width: 10%;
    @include breakpoint(max-width $medium)  {
      margin-bottom: 10px;
    }
  }
}
*/
/*#area {
  .city {
    float: left;
    width: 50%;
    padding-right: 20px;
    margin-bottom: 20px;
  }
  .map {
    float: left;
    margin-bottom: 20px;
    width: 50%;
    #map {
      width: 100%;
      height: 400px;
    }
    .wrapper {
      padding: 3px;
    }
    .node-type-region &, .node-type-city & {
      width: 100%;
      #map {
        height: 300px;
      }
    }

  }
  .distances {
    clear: left;
    display: block;
    .row {
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  @include breakpoint(max-width $medium)  {
    .city, .map {
      width: 100%;
      padding-right: 0;
    }
    .map #map {
      width: 100%;
      height: 200px;
    }
  }
}
*/

#complex {
  .content {
    float: left;
    width: 50%;
    padding-right: 20px;
  }
  .main-characteristics ul {
    float: left;
    width: 100%;
  }
  .image {
    float: left;
    width: 50%;
  }
  @include breakpoint(max-width $medium) {
    .content, .image {
      width: 100%;
    }
    .main-characteristics {
      display: none;
    }
  }
}

.row {
  .inline {
    @include breakpoint($medium) {
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.calendar {
  clear: both;
  float: left;
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  @include clearfix;
}

#particulars {
  clear: left;
  float: left;
  width: 100%;
  margin-top: 10px;
  padding-top: 20px;
  .node-complex & {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    @include breakpoint(max-width $large) {
      margin-bottom: 0;
      padding-top: 20px;
    }
  }
}

// Offers.
.period .label {
  display: none;
  /*
  @include breakpoint($large) {
    display: block;
  }
  */
}

#availability #period label {
  font-weight: bold !important;
}

#show-all-prices {
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin: 15px 0;
}

.offer {
  padding: 10px;
  .wrapper-left {
    width: 60%;
    float: left;
    min-height: 60px;
    .arrival {
      margin-bottom: 20px;
    }
    em {
      margin-left: 10px;
      @include breakpoint(max-width $large) {
        float: left;
        clear: left;
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
  .wrapper-middle {
    width: 40%;
    float: left;
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
  }
  .wrapper-right {
    text-align: center;
    width: 40%;
    float: left;
  }
  .period div {
    @include breakpoint(max-width $medium) {
      clear: left;
    }
  }
}

.flexible {
  .arrival, .departure {
    float: left;
  }
  .departure {
    margin-left: 5px;
  }
  //@include breakpoint(max-width $medium) {
    .offer {
      padding-left: 0;
    }
    .departure {
      clear: left;
      margin-left: 0;
    }
    label {
      display: none;
    }
  //}
  #from, #to {
    width: 150px;
  }
}

/*
@include breakpoint($medium) {
  .offer {
    padding: 10px 20px;
    .wrapper-left {
      width: 60%;
      min-height: inherit;
      .arrival {
        margin-bottom: 0;
      }
    }
    .wrapper-middle {
      text-align: left;
      width: 25%;
      font-size: 16px;
      margin-bottom: 0;
    }
    .wrapper-right {
      text-align: right;
      width: 15%;
      input.button {
        width: 120px;
      }
    }
  }
}
*/

ul.holiday-homes, .wrapper-photos {
  margin: 0 -10px 0 -10px;
  @include breakpoint(max-width $medium) {
    margin: 0;
  }
}

ul.photos {
  margin: 0;
}

@include breakpoint($medium $large) {
  .block-discounts, #block-helper-similar-holiday-homes, #recently-watched.four-columns {
    li.holiday-home:last-child {
      display: none;
    }
  }
  .destinations-blocks, #block-helper-accommodation-types {
    a.column:last-child {
      display: none;
    }
  }
}

div.holiday-home.last-minute, li.holiday-home,
li.complex {
  @include clearfix;
  vertical-align: middle;
  float: left;
  width: 25%;
  @include breakpoint(0 $medium){
    width:100%;
  }
  a {
    padding: 10px;
    float:left;
    height: 0;
    padding-bottom: 125%;
    .photos &{
      padding-bottom: 145%;
    }
    width:100%;
    position:relative;
    @include breakpoint (0 $medium){
      height:auto;
    }
    @include clearfix;
    &:hover {
      text-decoration: none !important;
    }

    .discounts {
      top:10px;
      left:10px;
      height:300px;
    }
    .page-blocks & {
      width: 100%;
    }
    .three-columns &, .photos & {
      @include breakpoint(max-width $medium) {
        width: 100%;
        clear: left;
      }
    }
    .title {
      margin-top: 5px;
      .holiday-homes.photos &{
        height:44px;
      }
      color: $secondary-color;
      font-weight: $bold-weight;
      font-family: $heading-font;
    }
    .button {
      margin: 10px 0;
    }
    .compare-check {
      margin: 10px 0;
      height: 22px;
      input, .label, .button {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    @include breakpoint($medium $large) {
      width: 100%;
      .photos &{
        width:100%;
      }
    }
    @include breakpoint(max-width $medium) {
      .discounts{
        display:none;
      }
      .title{
        height:auto !important;
      }
      margin: 0;
      padding: 20px 20px;
      @include breakpoint(0 $mobile){
        padding: $gutter-width/2;
        display:block;
        @include clearfix;
        .photos &{
          padding: $gutter-width/2;
        }
      }
      .price-info{
        clear:none;
        padding-top:0;
      }
      width: 100%;
      position: relative;
      .block-discount & {
        padding: 0;
      }
      .title {
        height: inherit;
        margin-top: 0;
        margin-bottom: 10px;
      }
      .content{
        padding-left: 0 !important;
      }
      .image {
        float: left;
        width: 25%;
        padding-right:15px;
        .block-discount & {
          width: 100%;
        }
        img {
          border: none;
          padding: 0;
        }
      }

      .content {
        padding-left: 15px;
        float: left;
        width: 75%;
      }

      .type, .advantage {
        font-size: 14px !important;
        .block-discount & {
          position: absolute;
          bottom: 40px;
          color: #fff;
          text-shadow: 1px 1px 1px #222;
        }
      }
      .type {
        .block-discount & {
          font-size: 16px !important;
          left: 10px;
        }
      }
      .advantage {
        .block-discount & {
          left: 200px;
        }
      }
      .starting-at {
        margin-left: 10px;
      }
      .button {
        display: none;
      }
      @include icon('right-open', 'before');
      &:before {
        font-size: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }
    }
  }
}

.page-blocks, .destinations-blocks, .compare-blocks {
  margin: 0 -10px 0 -10px;
}

.page-blocks, .destinations-blocks, .compare-blocks {
  @include breakpoint(max-width $medium) {
    margin: 0;
    .column + .column + .column + .column + .column + .column {
      display: none;
    }
    .show-more {
      display: none;
    }
  }
  @include breakpoint(max-width $large) {
    .show-more {
      display: none;
    }
  }
  .column {
    float: left;
    &.first {
      clear: left;
    }
    padding: $gutter-width/2;

    width: 25%;
    margin-bottom: 20px;
    h3 {
      height: 50px;
    }
    &:hover {
      text-decoration: none !important;
    }
    .title {
      margin-bottom: 10px;
    }
    div.read-more {
      margin-top: 10px;
    }
    @include breakpoint($medium $large) {
      width: 33.33%;
      &.first {
        clear: none;
      }
      &:nth-child(3n + 1) {
        clear: left;
      }
    }
    @include breakpoint(max-width $medium) {
      width: 100%;
      padding: 0;
      position: relative;
      h3 {
        position: absolute;
        top: 10px;
        left: 0;
        margin: 0 $gutter-width;
        font-size: 24px;
      }
      .content, div.button {
        display: none;
      }
      .image img {
        padding: 0;
        border: none;
        width:100%;
      }
      .price-info{
        position:absolute;
        bottom:10px;
        text-shadow: 1px 1px 1px #222;
        padding:0;
        margin:0;
        width:100%;
        color:white!important;
        .price-context,
        .price-values{
          color:white !important;
          > span{
            margin:10px;
            color:white !important;
          }
        }
        .price-context{
          .price-context-info{
            margin:0 10px;
            font-size:13px;
            float:left;
          }
          .discount-percentage{
            width:50%;
            margin:0  10px;
            float:right;
            font-size:14px;
            color:white !important;
          }
        }
        .price-values{
          .original-price,
          .price{
            margin:0 10px;
          }
          .price{

            font-size:20px !important;
            float:right !important;
            width:50%;
          }
        }
      }
      @include icon(angle-right, 'after');
      &:after {
        font-size: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }
    }
  }

  #rent, #col-alt {
    padding-left: 0 10px;
    background: none;
    li {
      padding-left: 33px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    border-radius: 5px;
    @include breakpoint(max-width $medium) {
      display: none;
    }
  }

  #rent .wrapper {
    padding: 20px;
  }

  .show-more {
    clear: left;
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
}

.page-blocks, .destinations-blocks {
  .column {
    h3 {
      color: $heading-font-color;
    }
    .content {
      color: $base-font-color;
      .read-more {
        color: $link-color;
      }
    }
    &:hover {
      background: $hover-background;
    }

    @include breakpoint(max-width $medium) {
      h3 {
        color: #fff;
        text-shadow: 1px, 1px, 1px, $base-font-color;
      }
      &:after {
        color: #fff !important;
        text-shadow: 1px, 1px, 1px, $base-font-color;
      }
    }
  }

  #rent {
    .wrapper {
      background: lighten($border-color, 5%);
    }
    ul {
      margin-left: 0 !important;
      li {
        list-style: none !important;
        margin-left: 0 !important;
        @include icon('right-open', 'before');
        background: none;
        &:before {
          margin-top: 2px;
          color: $tertiary-color;
          margin-left: -30px;
          width: 30px;
          float: left;
          display: block;
        }
      }
    }
  }
}

@include breakpoint($medium) {
  .destinations-blocks.two-columns .column {
    width: 50%;
  }
}

.destinations-blocks.three-columns .column {
  width: 33.33%;
}

@include breakpoint($medium $large) {
  .destinations-blocks.two-columns, .destinations-blocks.three-columns {
    a.column:last-child {
      display: inherit;
    }
  }
}

#datepicker {
  position: relative;
  //@include breakpoint(max-width $medium) {
    .ui-datepicker-group-middle {
      display: none;
    }
    .ui-datepicker-group-last {
      .ui-datepicker-title, .ui-datepicker-calendar {
        display: none;
      }
    }
    .ui-datepicker .ui-datepicker-next {
      //position: absolute;
      //top: -260px;
    }
    .ui-datepicker-group {
      width: 100%;
    }
    .ui-datepicker-group-last {
      width: auto !important;
      position: absolute;
      right: 0;
    }
  //}
}

.block-helper-search {
  .holiday-homes, .complexes {
    float: left;
    width: 100%;
    margin-top: 20px;
    clear: left;
    @include breakpoint(max-width $medium) {
      margin-top: 0;
      border-top: none;
    }
  }
  .sort {
    @include breakpoint(0 $mobile) {
      padding: $gutter-width;
    }
    float: left;
    width: 100%;
    position: relative;
    z-index: 10;
    a {
      float: left;
      line-height: 32px;
      margin-left: 10px;
      padding-left: 10px;
      font-size: 16px;
      &.list {
        width: inherit;
        margin-top: 0;
        margin-left: 0;
        padding-top: 0;
        border-top: none;
        border-left: none;
      }
      span {
        font-size: 16px;
        margin-left: 5px;
        line-height: 32px;
        &.fa-map-marker {
          font-size: 18px;
        }
      }
    }
  }
  .map-wrapper {
    clear: left;
    float: left;
    width: 100%;
    margin-top: 20px;
  }
  p.info {
    margin-top: 10px;
  }
}

.holiday-homes {
  &.search-result {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color;
    @include breakpoint(max-width $medium) {
      padding-top: 0;
      border: none;
    }
  }
}

#helper-search-search-content-form, .search-empty {
  @include breakpoint(max-width $medium) {
    padding: 0 $gutter-width;
    margin-bottom: 20px;
  }
}

.list, .view-content {
  > .holiday-home, > .complex {
    &:first-child {
      border-top: 1px solid $border-color;
    }
  }
  .holiday-home a, .complex a {
    position: relative;
    border-bottom: 1px solid $border-color;
    &:hover {
      text-decoration: none !important;
    }
    float: left;
    clear: left;
    width: 100%;
    padding: 20px 0;
    @include breakpoint(0 $mobile) {
      padding: $gutter-width/2 !important;
    }
    .image {
      float: left;
      width: calc(25% - #{$gutter-width});
    }
    .content {
      float: left;
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;
      .title {
        margin-bottom: 10px;
        font-size: 18px;
        font-family: $heading-font;
        color: $secondary-color;
        font-weight: $bold-weight;
      }
      .teaser {
        clear: left;
      }
    }
    .price-wrapper{
      float: right;
      width: 25%;
      .price-info{
        @include price-info;
      }
      @include breakpoint(0 $mobile){
        width:100%;
        float:none;
        .price-info{
          width:100%;
        }
      }
    }
    .show {
      float: right;
      width: 25%;
      div.arrow {
        margin-top: 10px;
        width: 100%;
      }
    }
    .price span.info {
      vertical-align: middle;
      display: inline-block;
    }
    .price div.info span {
      vertical-align: middle;
    }
    .price .info span {
      display: inline-block;
      &.text {
        margin-right: 5px;
      }
    }
    .characteristics {
      clear: both;
      width: 100%;
      float: left;
      font-size: 12px;
      padding: 5px 10px;
      background: $hover-background;
      ul {
        float: left;
        width: 100%;
        li {

          line-height: 26px;
          float: left;
          width: calc(100% / 4);
          height: 26px;
          &:before {
            margin-right: 10px;
            font-size: 16px;
          }
        }
      }
    }
    .last-minute {
      position: absolute;
      z-index: 1;
      top: 0;
      left: -20px;
      @include breakpoint(max-width $large) {
        left: -10px;
      }
      @include breakpoint(max-width $medium) {
        display: none;
      }
    }
    @include breakpoint(max-width $medium) {
      margin: 0;
      padding: 20px 0;
      width: 100%;
      .image {
        float: left;
        width: 25%;
        padding-right:15px;
        img {
          border: none;
          padding: 0;
        }
      }
      .price-wrapper{
        width:75%;
        float:left;
        .price{
          padding-left:0;
        }
      }
      .content {
        padding-right: 0;
        padding-left:0;
        float: left;
        width: 75%;
        .title {
          height: inherit;
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 16px;
        }
        .teaser {
          display: none;
        }
        @include icon('right-open', 'after');
        &:after {
          font-size: 16px;
          position: absolute;
          right: $gutter-width;
          top: calc(50% - 8px);
        }
      }
      .price, .show {
        width: 75%;
        padding-left: 15px;
      }
      .type, .starting-at {
      }
      .type {
        font-size: 14px;
      }

      .button, .characteristics, .info {
        display: none;
      }
    }
  }
}

#block-helper-search-holiday-homes {
  .list .holiday-home {
    .compare-check {
      position: absolute;
      bottom: 79px;
      right: 0;
    }
  }
  .photos .holiday-home {
    a {
      position: relative;
    }
    .compare-check {
      position: relative;
    }
  }
  .holiday-home {
    a {
      z-index: 1;
    }
    .compare-check {
      z-index: 2;
      input.compare {
        margin-right: 5px;
      }
      .label {
        display: inline;
      }
    }
  }
}

/* Last minute label */
.list .holiday-home .last-minute, .view-content .holiday-home .last-minute {
  background-image: none;
  left: 0 !important;
  top: 19px !important;
  width: calc(25% - #{$gutter-width});
  &::before {
    content: " ";
    background: rgba($overlay-color, .7);
    width: 100%;
    float: left;
    opacity: 0.85;
    height: 40px;
  }
  &::after {
    content: "LAST MINUTE";
    font-size: 20px;
    color: #fff;
    line-height: 1;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 14px;
  }
}

#block-helper-search-holiday-homes {
  .compare-check {
    @include breakpoint(max-width $large) {
      display: none;
    }
  }
}

.compare-blocks {
  div.title {
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 5px;

  }
  li:nth-child(4n+1) {
    clear: left;
  }
  li a {
    margin-top: 20px;
  }
}

.back-to-overview {
  clear: left;
  width: 100%;
  border-top: 1px solid;
  padding-top: 20px;
}

.all-regions {
  clear: left;
  float: left;
  width: 60%;
  .region {
    width: 50%;
    margin-bottom: 30px;
    float: right;
    h3 {
      margin-bottom: 10px;
    }
    h4 {
      margin-bottom: 5px;
    }
    .city a {
      @include icon(angle-right, 'before');
      &:before {
        margin-right: 10px;
        color: $tertiary-color;
      }
      font-size: 14px;
    }
  }
  .first {
    float: left;
    //margin-right: 60px;
    //margin-bottom: 100px;
  }
  @include breakpoint(max-width $medium) {
    width: 100%;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
    .region {
      width: 100%;
    }
  }
}

.page-node-5, .page-node-19973 { // DESTINATIONS, this needs to change!!!
  h2 {
    clear: left;
    margin-bottom: 30px;
  }
  .map-wrapper {
    float: left;
    width: 40%;
    @include breakpoint(max-width $medium) {
      width: 100%;
      padding: 0 $gutter-width 0 $gutter-width;
      border: none;
    }
  }
}

.node-type-contact-page #main #block-system-main {
  .block-rent {
    clear: left;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color;
  }
  @include breakpoint(max-width $large) {
    p img {
      display: none;
    }
  }

  @include breakpoint(max-width $medium) {
    h1, .node-contact-page {
      margin-left: 0;
      margin-top: 0;
      padding-left: $gutter-width/2;
      padding-right: $gutter-width/2;
    }
    p strong {
      font-size: 20px;
    }
    table tr td {
      font-size: 11px;
    }
  }

}

/* Rental plans */
.node-type-rent-page {
  #main #content > .block {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
  h2 {
    //clear: left;
    margin-top: 30px;
    //padding-top: 20px;
    //padding-bottom: 10px;
  }
  h3 {
    margin-top: 15px;
  }
  .field-advantages h3, .field-rent-faq h3, .field-body p {
    font-weight: normal;
    //font-family: Arial, Helvetica, "Nimbus Sans L", sans-serif; // THIS NEEDS TO BE N TYPOGRAPHY!!!
  }
  .field-body {
    p {
      //font-size: 18px;
      //line-height: 27px;
      //margin-bottom: 30px;
    }
  }
  .wrapper {
    margin: 20px 0 20px 0;
    position: relative;
  }
  .field-advantages {
    h3 {
      font-size: 16px;
      line-height: 24px;
      padding-left: 60px;
      font-weight: bold;
    }
    li {
      float: left;
      width: 33%;
      //padding-left: 65px;
      padding: 0 30px 20px 0;
      &:nth-child(3n) {
        padding-right: 0;
      }
      &:nth-child(4n) {
        clear: left;
      }
      @include breakpoint(max-width $medium) {
        width: 100%;
        h3 {
          vertical-align: bottom;
          margin: 10px 0;
        }
        p {
          padding-top: 5px;
        }
      }
    }
  }
  .field-rent-faq {
    float: left;
    padding-bottom: 20px;
    li {
      background: none;
      padding-left: 0;
      float: left;
      clear: left;
      width: 100%;
    }
  }
  .field-rent-review {
    clear: left;
    margin-bottom: 20px;
    h2 {
      padding-top: 20px;
      padding-bottom: 10px;
      margin-bottom: 0;
    }
    h3 {
      line-height: 25px;
    }
  }
  .field-rental-plans {
    clear: left;
    a.arrow {
      z-index: 99;
    }
    table {
      tr {
        td {
          line-height: 1;
          padding: 15px 10px;
          width: 25%;
          text-align: center;
          &:first-child {
            width: 300px;
            text-align: left;
            border-left: none;
          }
          h3 {
            margin-bottom: 5px;
            font-size: 16px;
          }
        }
        &:first-child, &:nth-child(2), &:last-child {
          td:first-child {
            border: none;
          }
        }
        &:first-child {
          td:nth-child(2) {
            border-top-left-radius: 5px;
          }
        }
      }
    }
    .popular {
      position: absolute;
      top: 0;
      left: 50%;
      width: 25%;
      height: 100%;
      &:before, &:after {
        content: " ";
        position: absolute;
        left: -1px;
        width: 100%;
        height: 50px;
      }
      &:before {
        top: -50px;
        border-bottom: none;
        border-top-radius: 5px;
      }
      &:after {
        bottom: -50px;
        border-top: none;
        border-bottom-radius: 5px;
      }
    }
  }
}

.node-type-rent-page #rent-reviews {
  .review {
    .content {
      height: 190px;
      width: 100%;
      &:before {
        top: 199px;
      }
      &:after {
        top: 199px;
      }
    }
    .name {
      width: 190px;
    }
  }
}

#helper-registration-form {
  width: 100%;
  float: left;
  .form-item {
    width: 100%;
    float: left;
    clear: left;
    label {
      width: 20%;
      padding-right: 10px;
      float: left;
    }
    input, textarea, select {
      width: 80%;
      float: left;
    }
    @include breakpoint(max-width $medium) {
      margin-bottom: 0;
      label {
        width: 100%;
        font-weight: bold;
      }
      input, textarea, select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  #edit-salutation {
    float: left;
    .form-item-salutation {
      float: none;
      display: inline;
      label {
        width: inherit;
        float: none;
        font-weight: normal !important;
      }
      input {
        width: 14px;
        float: none;
      }
    }
  }
  #edit-submit {
    margin-bottom: 20px;
    margin-left: 20%;
    @include breakpoint(max-width $medium) {
      margin-left: 0;
    }
  }
  textarea {
    height: 100px;
    line-height: 1.25;
  }
  @include breakpoint(max-width $medium) {
    .form-item-street,
    .form-item-house-number,
    .form-item-zip-code,
    .form-item-city,
    .form-item-holiday-home-name,
    .form-item-holiday-home-city {
      display: none;
    }
  }
}

/* Pager */
div.item-list {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  float: left;
  ul.pager {
    vertical-align: bottom;
    display: inline-block;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      float: left;
      a, &.pager-ellipsis, &.pager-current {
        float: left;
        margin: 0 0 0 4px;
        padding: 8px 12px;
        font-size: 16px;
        @include breakpoint(max-width $medium) {
          font-size: 18px;
          padding: 12px 16px;
        }
      }
    }
  }
}

/* Add review */
.page-node-add-review {
  #helper-review-form {
    float: left;
  }
  .holiday-home {
    float: right;
    width: auto;
    max-width: 35%;
  }
  @include breakpoint(max-width $large) {
    .holiday-home {
      display: none;
    }
  }
}

#helper-review-form {
  .form-item-review-positive label,
  .form-item-review-negative label {
    font-weight: bold;
  }

  @include breakpoint(max-width $medium) {
    .labels {
      display: none;
    }
    .form-type-radios {
      label {
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
      }
      .form-radios {
        margin-bottom: 20px;
        label {
          font-weight: normal;
          font-size: 14px;
          margin-left: 5px;
          line-height: 21px;
        }
      }
    }
    .form-type-textfield {
      float: left;
      clear: left;
      width: 100%;
      label {
        display: block;
      }
      input.form-text {
        width: 100%;
      }
    }
    #edit-submit {
      margin-top: 10px;
    }
  }
  @include breakpoint($medium) {
    .form-item {
      margin-bottom: 20px;
    }
    float: left;
    .labels {
      clear: left;
      float: left;
      padding-left: 199px;
      .label {
        float: left;
        font-weight: bold;
        padding: 10px 0;
        width: 100px;
        text-align: center;
        &:first-child {
          width: 101px;
        }
      }
    }
    .form-type-radios, .form-type-textfield, .form-type-textarea, .form-type-date-select {
      label {
        float: left;
        clear: left;
        width: 200px;
        text-align: right;
        vertical-align: middle;
        padding: 5px 10px 0 0;
      }
    }
    .form-item-travel-group label {
      width: inherit;
    }
    .form-type-radios {
      float: left;
      clear: left;
      margin: 0;
      label {
        padding: 10px 10px 10px 0;
      }
    }
    .form-radios {
      float: left;
      .form-type-radio {
        float: left;
        width: 100px;
        margin: 0;
        text-align: center;
        padding: 10px 0;
        label {
          display: none;
        }
      }
    }

    .form-item-wifi {
      margin-bottom: 30px;
    }

    div.form-item-travel-group {
      border-left: none;
      border-right: none;
      border: none;
      margin-bottom: 30px;
      label {
        border-left: none;
        border-right: none;
        border: none;
        text-align: left;
        margin: 0;
        padding: 0;
        h2 {
          margin-bottom: 10px;
        }
      }
      .form-radios {
        float: none;
        .form-type-radio {
          clear: left;
          width: inherit;
          border: none;
          border: none;
          margin: 5px 0;
          padding: 0;
          label {
            display: block;
            float: right;
            margin: 0;
            padding: 0;
            border-left: none;
            border-right: none;
            border: none;
            text-align: left;
            margin-left: 5px;
          }
        }
      }
    }

    input.form-text {
      width: 406px;
    }
    .form-textarea-wrapper {
      display: inline-block;
      textarea {
        display: inline;
        width: 406px;
      }
    }

    input.form-submit {
      margin-left: 201px;
    }
  }
}

.inactive {
  margin-bottom: 20px;
  @include breakpoint(max-width $medium) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

#helper-newsletter-sign-out-form {
  margin-bottom: 20px;
}

/* Member section */
#user-profile-form, #user-register-form {
  .form-item-newsletter {
    margin: 10px 0 20px 0;
  }
}

.view-user-tabs {
  input.form-submit {
    left: 36px;
    top: 5px;
  }
  .vbo-fieldset-select-all {
    label {
      display: none;
    }
    .form-type-checkbox {
      position: absolute;
      left: 10px;
      top: -24px;
    }
  }
  .row {
    border: 1px solid;
    margin: 10px 0;
    .form-item, .image, h4 {
      display: inline-block;
    }
    .form-item {
      vertical-align: top;
      margin: 26px 5px 0 10px;
    }
    .image {
      padding: 5px;
      border: 1px solid;
      margin-right: 10px;
      margin-bottom: 10px !important;
    }
    .image, h4 {
      vertical-align: top;
      margin-top: 10px;
    }
  }
}

.general-request {
  margin-bottom: 20px;
}

.block-last_minute {
  > .last-minute {
    position: relative;
    &:before {
      position: absolute;
      z-index: 99;
      content: 'Last minute';
      top: 80px;
      left: 20px;
      pointer-events: none;
      color: #fff;
      text-shadow: 1px 1px 1px #222;
      font-weight: bold;
      font-size: 20px;
      border: 2px solid #fff;
      text-transform: uppercase;
      line-height: 1;
      padding: 5px;
      margin: 0;
      @include breakpoint(max-width $medium) {
        top: 60px;
        left: 10px;
      }
    }
  }
}

/* Admin menu */
@include breakpoint(max-width $large) {
  #admin-menu-menu li:not(:first-child),
  #admin-menu-account .welcome {
    display: none;
  }
}

#admin-menu-wrapper {
  margin: 0 auto;
  max-width: $max_width;
  //min-width: 960px;
  padding: 0 10px;
}


body.adminimal-menu #admin-menu .dropdown li.admin-menu-action.admin-menu-account a {
  background: none !important;
  background-image: linear-gradient(#4e9fea 0%, #417ed2 100%) !important;
  background-image: -ms-linear-gradient(#4e9fea 0%, #417ed2 100%) !important;
  background-image: -webkit-linear-gradient(#4e9fea 0%, #417ed2 100%) !important;
}

body.adminimal-menu #admin-menu .dropdown li.admin-menu-action.admin-menu-account a:hover {
  background-image: linear-gradient(#1c9fea 0%, #0a7ed2 100%) !important;
  background-image: -ms-linear-gradient(#1c9fea 0%, #0a7ed2 100%) !important;
  background-image: -webkit-linear-gradient(#1c9fea 0%, #0a7ed2 100%) !important;
}

body.adminimal-menu div#admin-menu {
  background: #333;
}

#admin-menu li {
  border-left: 1px solid #000;
}

#admin-menu .dropdown .welcome span {
  border: medium none !important;
  color: #ccc;
  font-family: "Open Sans", "Segoe UI", "Helvetica", sans-serif;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  padding: 5px 10px;
}

#admin-menu .dropdown .welcome:hover {
  background: none !important;
}

#reliability {
  .reliability {
    margin: 40px 0;
    .logo-search-and-menu & {
      margin: 0;
    }
    text-align: center;
    > div {
      display: inline-block;
      white-space: nowrap;
      margin: 20px 10px 0 10px;
      > div {
        display: inline-block;
        line-height: 38px;
        vertical-align: middle;
        margin: 0 7px;
      }
      > div:not(.label) {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 38px;
        width: 38px;
      }
    }
    .label {
      font-weight: bold;
    }
    .assurance {
      .recron {
        width: 87px;
        background-image: url(#{$base_theme_path}/images/icons/recron.png);
      }
      .europeesche-verzekeringen {
        width: 127px;
        background-image: url(#{$base_theme_path}/images/icons/europeesche-verzekeringen.png);
      }
      .vvv {
        width: 90px;
        background-image: url(#{$base_theme_path}/images/icons/vvv.png);
      }
    }
    .secure {
      .i-deal {
        background-image: url(#{$base_theme_path}/images/icons/i-deal.png);
      }
      .master-card {
        width: 56px;
        background-image: url(#{$base_theme_path}/images/icons/master-card.png);
      }
      .visa {
        width: 52px;
        background-image: url(#{$base_theme_path}/images/icons/visa.png);
      }
      .paypal {
        width: 76px;
        background-image: url(#{$base_theme_path}/images/icons/paypal.png);
      }
    }
  }
}
