// Holiday home page.
body.node-type-holiday-home {
  #block-helper-recently-watched-3 {
    border-top: none;
    border-bottom: none;
    #recently-watched {
      @include clearfix;
      @include breakpoint($tab) {
        margin-right: $sidebar-width + $gutter-width;

      }
      @include breakpoint(0 $mobile) {
        margin-right: 0;
      }
      ul.holiday-homes {
        margin-left: -$gutter-width;
        margin-right: 0;
        li {
          a {
            &:hover {
              background: none;
            }
            padding: $gutter-width 0 $gutter-width $gutter-width;
          }
        }
      }
    }
  }
}