/* Imports */
@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";

/* Colors from layout */
#header-top {

  //@include background-image(linear-gradient(lighten($primary-color, 5%), darken($primary-color, 15%)));
}

.search-small {
  @include breakpoint(max-width $medium) { 
    label {
      color: #fff;
      &:before {
        color: #fff;
      }
    }
  }
}

/* Search Holiday Homes block on frontpage (in slideshow) */
#content-top {
  #block-helper-search-search-holiday-homes {
    background: $search-home-bg-color;
  }
}

/* Last minutes block on frontpage and holiday homes on complex page */
#block-helper-last-minutes, .node-type-complex .block-holiday-homes, .node-type-region .block-holiday-homes, .node-type-city .block-holiday-homes, .node-type-collection .block-holiday-homes, .node-type-topic .block-holiday-homes {
  .all {
    border-left: 1px solid $border-color;
    a {
      &:after {
        color: $primary-color;
      }
    }
  }
}

#block-helper-newsletter-sign-up {
  border-bottom: 1px solid $border-color;
}

#footer {
  background: $secondary-color;
  .bottom {

    li {
      border-left: 1px solid #fff;
    }
  }
}

@include breakpoint($large) {

  .responsive-tabs {
    background: url(../../images/tabs-bg.png) right top no-repeat;
  }
}


@include breakpoint(max-width $large) {
  #search-filters-toggle-label {
    background: $form-background-color;
    color: $secondary-color;
  }
}

.view-blog {
  .views-row {
    border-bottom: 1px solid $border-color;
    a.image {
      border: 1px solid $border-color;
    }
  }
  .view-header {
    border-bottom: 1px solid $border-color;
  }
}

#content, #content-bottom {
  .block {
    border-top: 1px solid $border-color;
  }
  .destinations-blocks {
    border-bottom: 1px solid $border-color;
  }
}

#block-helper-recently-watched-3 {
  border-bottom: 1px solid $border-color;
}

@include breakpoint(max-width $medium) {
  .review-average-favorites {
    background: rgba(0,0,0, 0.5);
    .star-reviews {
      color: #fff !important;
    }
  }
}


h2.holiday-home-xl {
  border: 1px solid $border-color;
  background: lighten($border-color, 5%);
}

.calendar, #particulars {
  border-top: 1px solid $border-color;
}
.complex {
  #particulars {
    border-top: none;
    border-bottom: 1px solid $border-color;
  }
}
div.holiday-home, li.holiday-home, 
li.complex {  
  a {
    &:hover {
      background: $hover-background;
    }
    @include breakpoint(max-width $medium) {
      &:after {
        color: $primary-color !important;
      }
    }
  }
}

.block-discounts li.holiday-home {
  .advantage {
    color: $advantage-color; 
  }
  .price {
    .price-original {
      color: $base-font-color;
    }
  }
}

.page-blocks, .destinations-blocks {
  .column {
    h3 {
      color: $heading-font-color;
    }
    .content {
      color: $base-font-color;
      .read-more {
        color: $link-color;
      }
    }
    &:hover {
      background:$hover-background;
    }

    @include breakpoint(max-width $medium) {
      h3 {
        color: #fff;
        text-shadow: 1px 1px 1px $base-font-color;
      }
      &:after {
        color: #fff !important;
        text-shadow: 1px 1px 1px $base-font-color;
      }
    }
  }
  
  #rent {
    .wrapper {
      background: lighten($border-color, 5%);  
    }
  }  
}

.block-helper-search {
  .sort {
    a {
      border-left: 1px solid $border-color;
    }
  }
}

.list, .view-content {
  .holiday-home a, .complex a {
    &:hover {
      background: $hover-background;
      .characteristics {
        background: darken($hover-background, 5%);
      }
    }
   // border-bottom: 1px solid $border-color;
    .content {
      .teaser {
        color: $base-font-color;
      }
    }
    .characteristics {
     // background: lighten($border-color,5%);
      //border: 1px solid $border-color;
    }
    @include breakpoint(max-width $medium) {
      .content {
        &:after {
          color: $primary-color !important;
        }
      }
      .type {
        color: lighten($base-font-color, 25%);
      }
    }
  }
}

.ui-tooltip {
  background: #fff;
}

.all-regions {
  .region {
    .city a {
      @include icon(angle-right, 'before');
      &:before {
        margin-right: 10px;
        color:$tertiary-color;
      }

    }
  }
}


.node-type-faq-page #main #block-system-main {
  .category {
    border-top: 1px dotted darken($border-color, 10%);
  }
  #entries .wrapper {
    legend {
      color: $base-font-color;
      a {
        color: $base-font-color;
      }
    }
    fieldset.collapsed legend {
      border-bottom: 1px solid $border-color;
      color: $link-color;
      a {
        color: $link-color;
      }
    }
    .fieldset-wrapper {
      border-bottom: 1px solid $border-color;
    }
  }

}
.node-type-contact-page #main #block-system-main {
  .block-rent {
    border-top: 1px solid $border-color;
  }
}

/* Rental plans */
.node-type-rent-page {
  .field-advantages h3, .field-rent-faq h3, .field-body p {
    color: lighten($base-font-color, 10%);
  }
  .field-rental-plans {
    table {
      tr {
        td {
          border: 1px solid $border-color;
        }
        &:first-child {
          td:nth-child(3) {
            border-top: 1px solid lighten($border-color, 5%);
          }
        }
        &:last-child td:nth-child(3) {
          border-bottom: 1px solid lighten($border-color, 5%);
        }
        td:nth-child(3) {
          background: lighten($border-color, 5%);
        }
      }
    }
    .popular {
      box-shadow: 6px 0 4px  -4px #b3b3b3, -6px 0 4px  -4px #b3b3b3;
      &:before, &:after {
        background: lighten($border-color, 5%);
        border: 1px solid $border-color;
      }
    }
  }
}

#helper-review-form {
  .form-item-review-positive label {
    color: $positive-color;
  }
  
  .form-item-review-negative label {
    color: $negative-color;
  }
  
  @include breakpoint($medium) {
    .labels {
      border-bottom: 1px solid $border-color;
      .label {
        border-right: 1px solid $border-color;
        border-top: 1px solid $border-color;
        &:first-child {
          border-left: 1px solid $border-color;
        }
      }
    }
    .form-type-radios {
      border-bottom: 1px solid $border-color;
      label {
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
      }
    }
    .form-radios {
      .form-type-radio {
        border-right: 1px solid $border-color;
      }
    }
    .form-item-friendliness, .form-item-location, .form-item-value-for-money, .form-item-wifi {
      background: lighten($border-color, 5%);
    }
  }
}

/* Colors from style */
#header-top {
  .questions {
    color: #fff !important;
  }
  a {
    color: #fff !important;
  }
}

/* Navigation */
#main-menu {
  ul {
    li {

      border-right: 1px solid $primary-color;
      a {
        color: $primary-color;
      }
      a:hover, a.active {
        color: $tertiary-color;
      }
    }
  }
}

#secondary-menu {
  ul {
    li {
      a {
        color: #fff;
      }
    }
  }
}


/* Images */
.complex, .column, .node-type-article #block-system-main .image {

}


.holiday-home, .complex, .column {
  img:hover {
    border-color: darken($border-color, 10%);
  }
}

.image-slide .image .slider {
  background: #fff url("/sites/all/themes/flean_rfov/images/ajax-loader.gif") center center no-repeat;
}


.star-reviews {
  color: $star-reviews-color;
  a, a:visited, a:hover {
    color: $star-reviews-color !important;
  }
}

/* Pager */
div.item-list {
  ul.pager {
    li {
      color: $base-font-color;
      a, &.pager-ellipsis, &.pager-current {
        color: $base-font-color;
        border: 1px solid $border-color;
        &:hover {
          background-color: $primary-color;
          color: #FFFFFF;
        }
      }
      &.pager-current {
        background-color: $primary-color;
        color: #FFFFFF;
      }
    }
  }
}

/*  List pages */
#block-helper-search-holiday-homes, #block-helper-search-complexes {
  .filters {
    a {
      color: #fff;
      background: $secondary-color;
      border: 1px solid darken($secondary-color, 5%);
      .erase {
        color: #fff;
      }
      &:hover {
        color: #fff;
        background: $tertiary-color;
        border: 1px solid darken($border-color, 5%);
      }
    }
  }
}

.sort {
 .view {
   float: right;
    a {
      &.list.active {
        color: $tertiary-color;
      }
      &.photos.active {
        color: $tertiary-color;
      }
      &.map.active {
        color: $tertiary-color;
      }
    }
  }
}

/* Tabs */
.resp-tabs-container, .resp-tabs-list {
  .number-of-reviews {
    background: $border-color;
  }
}

.characteristics-of-trust {
  border: 1px solid $border-color;
  li {
    border-top: 1px solid $border-color;
  }
}


.not-front #reviews {
  .add, .ratings {
    border-bottom: 1px solid $border-color;
  }
  .average-rating {
    background: $primary-color;
    color: #fff;
  }

  .ratings {
    .averages {
      .outer {
        background: $border-color;
      }
      .label {
        background: $primary-color;
        color: #fff;
      }
    }
  }
  .review {
    border-bottom: 1px solid $border-color;
    .content {
      .period-of-stay-and-name {
        color: $star-reviews-color;
      }
      .comment-owner {
        color: $star-reviews-color;
      }
    }
  }
}

.offers {
  .offer {
    &:nth-child(odd) {
      background: #fff;
    }
    &:nth-child(even) {
      background: lighten($search-home-bg-color, 5%);
    }
    .wrapper-left {
      em {
        color: $secondary-color;
      }
    }
  }
  .selected, .alternative {
    border-bottom: 1px solid $border-color;
  }
  .selected {
    border-top:  1px solid $border-color;
  }
}
.flexible .offer {
  float: left;
  background: #fff;
}

.legenda-block {
  .legenda {
    background-color: $legenda-available-color;
    border: 1px solid darken($border-color, 5%);
    &.available {
      background-color: $legenda-available-color;
    }
    &.blocked {
      background-color: $legenda-blocked-color;
      color: #fff;
    }

  }
}

#supplements {
  background: url("/sites/all/themes/flean_rfov/images/ajax-loader.gif") center center no-repeat;
  border-top: 1px solid $border-color;
  .supplements-wrapper {
    background: #fff;
  }
}
#supplements.supplements-complete {
  border-top: none;
  .supplements-wrapper {
    background: lighten($search-home-bg-color, 5%);
  }
}

#block-helper-reviews-homepage, #rent-reviews {
  background: $search-home-bg-color;
  .review {
    .content {
      background: #fff;
      border: 1px solid #eedebf;
      &:before {
        border-color: #eedebf transparent transparent #eedebf;
      }
      &:after {
        border-color: #fff transparent transparent #fff;
      }
    }
    .image {
      background: #fff;
      border: 1px solid #eedebf;
    }
  }
}

.page-book {
  #main {
    #cost-overview-wrapper {
      background:$form-background-color;
      div {
        &.total-cost {
          border-top: 1px solid darken($border_color, 15%);
        }
      }
    }
  }
}

/* Easy responsive tabs */
.resp-tabs-list li {
  color: $primary-color;
}

.resp-tabs-container {
  background-color: #fff;
}

.resp-tab-content {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.resp-tabs-list li.resp-tab-active, .resp-tabs-list li:hover {
  background: #fff;
  border: 1px solid $border-color;
  color: $tertiary-color !important;
}

h2.resp-accordion {
  border-bottom: 1px solid $border-color;
  background: lighten($border-color, 15%) !important;
  &:hover {
    background: lighten($border-color, 5%) !important;
  }
}

h2.resp-tab-active {
  border-bottom: 0px solid $border-color !important;
}

h2.resp-tab-title:last-child {
  border-bottom: 12px solid $border-color !important;
}

.resp-vtabs .resp-tabs-container {
  background-color: #fff;
  border: 1px solid $border-color;
}

.resp-vtabs li.resp-tab-active {
  border: 1px solid $border-color;
  background-color: #fff;
}

.resp-arrow {
    border-top: 12px solid $secondary-color;
}

h2.resp-tab-active span.resp-arrow {
    border-bottom: 12px solid $secondary-color;
}

h2.resp-tab-active {
  background: lighten($border-color, 5%) !important;
}

.resp-easy-accordion .resp-tab-content {
  border: 1px solid $border-color;
}

.resp-easy-accordion .resp-tab-content:last-child {
  border-bottom: 1px solid $border-color !important;
}

h2.resp-accordion:first-child {
  border-top: 1px solid $border-color !important;
}

@media only screen and (max-width: $large) {

  .resp-vtabs .resp-tab-content {
    border: 1px solid $border-color;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid $border-color !important;
  }
}

/* Select or Die */
.sod_select, #helper-search-holiday-homes-form .form-type-textfield  {
  &::before {
    /* CARAT */
    border-top-color: $secondary-color !important;
  }
}
.sod_select.open:before {
  /* CARAT */
  border-bottom-color: $secondary-color !important;
}

.sod_select .sod_label {
  border-color: darken($border-color, 5%) !important;
  color: $base-font-color !important;
}

.sod_select .sod_list_wrapper {
  border-color: $border-color !important;
  color: $base-font-color !important;
}

/* Hover state for options, also used when a user uses his/hers up/down keys */
.sod_select .sod_option.active {
  background: $tertiary-color;
  color: #fff;
}


/* Video thumb */
.play-button {
  .fa-play {
    color: $secondary-color;
  }
  .fa-circle {
    color: #fff;
    opacity: 0.5;
  }
}
  
/* Admin tabs  */
div.tabs {
  border-bottom-color: $border-color;
}

ul.primary li a:link,
ul.primary li a.active,
ul.primary li a:active,
ul.primary li a:visited,
ul.primary li a:hover,
ul.primary li.active a {

  background: mix($primary-color, lighten($border-color, 10%), 20%);
  color: $base-font-color;
  border-color: $border-color;
}

ul.primary li a:hover,
ul.primary li.active a,
ul.primary li.active a.active,
ul.primary li.active a:active,
ul.primary li.active a:visited {

  background: #fff;
  border-bottom-color: #fff;
  color: $base-font-color;
}
ul.primary li a:hover {
  color: $base-font-color;
}
ul.primary li.active a:hover {
  color: $base-font-color;
}

// Datepicker
#edit-check-in, #edit-check-out {
  &::placeholder {
    color: $base-font-color;
  }
}