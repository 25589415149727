@mixin stars() {

  font-family: 'icons';
  line-height: 20px;
  &:before {
    color: $tertiary-color;
    font-size: 18px;
    letter-spacing: 3px;
  }
  height: 20px;
  width: 96px;
  clear: left;
  float: left;

  &.rating-0 {
    &:before {
      content: "\E828 \E828 \E828 \E828 \E828";
    }
  }

  &.rating-5 {
    &:before {
      content: "\f123 \E828 \E828 \E828 \E828";
    }
  }

  &.rating-10 {
    &:before {
      content: "\f123 \E828 \E828 \E828 \E828";
    }
  }

  &.rating-15 {
    &:before {
      content: "\E827 \E828 \E828 \E828 \E828";
    }
  }
  &.rating-20 {
    &:before {
      content: "\E827 \E828 \E828 \E828 \E828";
    }
  }

  &.rating-25 {
    &:before {
      content: "\E827 \f123 \E828 \E828 \E828";
    }
  }

  &.rating-30 {
    &:before {
      content: "\E827 \f123 \E828 \E828 \E828";
    }
  }

  &.rating-35 {
    &:before {
      content: "\E827 \E827 \E828 \E828 \E828";
    }
  }
  &.rating-40 {
    &:before {
      content: "\E827 \E827 \E828 \E828 \E828";
    }
  }

  &.rating-45{
    &:before {
      content: "\E827 \E827 \f123 \E828 \E828";
    }
  }

  &.rating-50 {
    &:before {
      content: "\E827 \E827 \f123 \E828 \E828";
    }
  }

  &.rating-55 {
    &:before {
      content: "\E827 \E827 \E827 \E828 \E828";
    }
  }

  &.rating-60 {
    &:before {
      content: "\E827 \E827 \E827 \E828 \E828";
    }
  }

  &.rating-65 {
    &:before {
      content: "\E827 \E827 \E827 \f123 \E828";
    }
  }
  &.rating-70 {
    &:before {
      content: "\E827 \E827 \E827 \f123 \E828";
    }
  }
  &.rating-75 {
    &:before {
      content: "\E827 \E827 \E827 \E827 \E828";
    }
  }
  &.rating-80 {
    &:before {
      content: "\E827 \E827 \E827 \E827 \E828";
    }
  }

  &.rating-85{
    &:before {
      content: "\E827 \E827 \E827 \E827 \f123";
    }
  }

  &.rating-90 {
    &:before {
      content: "\E827 \E827 \E827 \E827 \f123";
    }
  }

  &.rating-95 {
    &:before {
      content: "\E827 \E827 \E827 \E827 \E827";
    }
  }
  &.rating-100 {
    &:before {
      content: "\E827 \E827 \E827 \E827 \E827";
    }
  }
}
