@mixin luxury-level() {
  font-family: 'icons';
  line-height: 20px;
  &:before {
    color: $tertiary-color;
    font-size: 18px;
    letter-spacing: 3px;
  }
  height: 20px;
  width: 100px;
  clear: left;
  float: left;

  &.rating-1 {
    &:before {
      content: "\E827";
    }
  }

  &.rating-2 {
    &:before {
      content: "\E827 \E827";
    }
  }

  &.rating-3{
    &:before {
      content: "\E827 \E827 \E827";
    }
  }

  &.rating-4 {
    &:before {
      content: "\E827 \E827 \E827 \E827";
    }
  }

  &.rating-5 {
    &:before {
      content: "\E827 \E827 \E827 \E827 \E827";
    }
  }
}
