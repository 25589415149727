html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: $base-font;
  font-weight: 400;
  color: $base-font-color;
  @include breakpoint(0 $tab){
    font-size:13px;
  }
  @include breakpoint($tab $wide){
    font-size: 14px;
  }
  @include breakpoint($wide){
    font-size:14px;
  }

  line-height: 1.42857143;
  background-color: #ffffff;
}

.logo-small {
  color: #fff;
  font-size: 26px;
}

h1, h2, h3 {
  font-family: $heading-font;
  color: $heading-font-color;
  font-weight: normal;
  line-height: 1.1;
  margin: 0 0 10px 0;
  font-weight: $bold-weight;
}

h1 {
  font-size: 36px;
  @include breakpoint(0 $tab){
    font-size:30px;
  }
}

h2 {
  font-size: 30px;
  @include breakpoint(0 $tab){
    font-size:24px;
  }
}

h3 {
  font-size: 22px;
  @include breakpoint(0 $tab){
    font-size:18px;
  }

  #sidebar-first & {
    font-family: $base-font;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 5px 0;
  }
}
h4 {
  font-size: 18px;
  #block-helper-newsletter-sign-up & {
    font-size: 14px;
  }
  @include breakpoint(0 $tab){
    font-size:16px;
  }
}

@include breakpoint(max-width $medium) {
  h1 {
    font-size: 24px;
    margin-top: 10px;
    .sidebar-first & {
      font-size: 20px;
    }
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
}


h4, h5, h6 {
  margin: 0 0 10px 0;
}



h5, h6 {
  font-weight: bold;
}

p {
  margin: 0 0 10px;
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  .read-more {
    white-space: nowrap;
  }
}

a:hover, a:active {
  outline: 0;
}

strong, b {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

#footer {
  h3, div {
    color: #fff;
  }
  a {
    color: #fff;
  }
}

a {
  .starting-at, .type {
    color: $base-font-color;
  }
  .price, .price div {
    &:hover {
      text-decoration: none !important;
    }
  }
  .starting-at {
    font-size: 20px;
  }
  .type {
    font-size: 12px;
  }
  &:hover div {
    text-decoration: none !important;
  }
}

.info, .author, .terms {
  color: #959595;
}
.info {
  font-size: 12px;
}

#footer .block div {
  line-height: 25px;
}

/*
#characteristics, #layout, #supplements, #area {
  li {
    list-style-position: inside;
    list-style-image: url(#{$base_theme_path}/images/list-bg.png);
  }
}
*/

#block-system-main .content {
  ul {
    margin: 0 0 10px 20px;
    li {
      list-style: disc outside;
      margin: 0 0 10px 0;
    }
  }
}

.node .content,
.field-body,
.field-body-short {
  ol,
  ul {
    margin: 15px 0 15px 20px;
    padding-left:10px;
    li {
      margin: 0 0 5px 0;
    }
  }
  ul li{
    list-style:disc;
  }
  ol li {
    list-style-type: decimal;
  }
}

