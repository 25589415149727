
@mixin button-general($color: $tertiary-color) {
  background: $color;
  border: none;
  border-width: 0;
  //border-bottom: 1px solid #b3b3b3;
  text-align: center;
  vertical-align: top;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding: 0 20px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 2px;
  font-family: $base-font;
  &:hover {
    //@include gradient-image ($image, $color-top, darken($color-bottom, 5%));
    background:darken($color, 5);
  }

  .ie & {
    position: relative;
    z-index: 1;
  }

  .ie &:hover {

  }
}
