
@mixin price-info {
  @include clearfix;
  clear:both;
  margin-bottom:10px;
  @include breakpoint (0 $medium){
    padding-top:10px;
  }
  .price-context {
    color: $base-font-color;
    margin-bottom:3px;
    font-size:12px;
    .discount-percentage{
      float:right;
      color: $advantage-color;
    }
  }
  .price-values {
    clear:both;
    color: $base-font-color;
    font-size: 20px;
    line-height: 20px;
    @include clearfix;
    .price{
      float:left;
      clear:none;
    }
    .info{
      color:$secondary-color;
      .info-icon{
        &:before{
          font-size:16px;
        }
      }
    }
    .original-price {
      float:left;
      clear:none;
      text-decoration: line-through;
      font-size: 10px;
      margin-right: 10px;
    }
  }
}
